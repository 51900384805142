import { useState } from "react";
import { IconButton, Drawer, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as SVGAccueil } from "../../../images/accueil.svg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AllInboxOutlinedIcon from "@mui/icons-material/AllInboxOutlined";

import { Box, Button, NavLink, Text } from "@/ui";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useMongoContext } from "@/provider/common/MongoProvider";
import {
  setAccessToken,
  setIdToken,
  setRefreshToken,
} from "@/utils/localStorage";
import { ModalMobile } from "@/ui/ModalMobile";

export const BurgerMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const navigationToWithPeriod = (to: string) => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let params = queryString.stringify(
      { period: period.getTime() },
      { arrayFormat: "index" }
    );
    navigate(`${to}?${params}`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box position="fixed" zIndex={20} top={10} left={20}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon style={{ fontSize: "35px" }} />
        </IconButton>
      </Box>

      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250, minHeight: "80%" }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box position="absolute" right={0} top={0}>
            <IconButton
              onClick={toggleDrawer(false)}
              sx={{ ml: "auto", display: "block" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <div className="logo-cofidoc" />
            <Box height="1px" width="100%" bgcolor="#d5d5d5" />
            <NavLink
              onClick={() => navigate("/home")}
              selected={location.pathname.includes("home")}
            >
              <SVGAccueil
                style={{
                  width: "25px",
                  height: "25px",
                  color: location.pathname.includes("home")
                    ? theme.palette.primary.main
                    : "#6a6a6a",
                }}
              />
              <Box p={1} />
              <Text m={0}>Accueil</Text>
            </NavLink>
            <Box height="1px" width="100%" bgcolor="#d5d5d5" />
            <NavLink
              onClick={() => navigationToWithPeriod("/account/report")}
              selected={location.pathname.includes("account/report")}
            >
              <AllInboxOutlinedIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: location.pathname.includes("account/report")
                    ? theme.palette.primary.main
                    : "#6a6a6a",
                }}
              />
              <Box p={1} />
              <Text m={0}>Mes comptes rendus de facturation</Text>
            </NavLink>
            <Box height="1px" width="100%" bgcolor="#d5d5d5" />
            <NavLink
              onClick={() => navigationToWithPeriod("/account/refund")}
              selected={location.pathname.includes("account/refund")}
            >
              <AccountBalanceOutlinedIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: location.pathname.includes("account/refund")
                    ? theme.palette.primary.main
                    : "#6a6a6a",
                }}
              />
              <Box p={1} />
              <Text m={0}>Mes paiements reçus</Text>
            </NavLink>
            <Box height="1px" width="100%" bgcolor="#d5d5d5" />
            <NavLink
              onClick={() => navigationToWithPeriod("/account/bill")}
              selected={location.pathname.includes("account/bill")}
            >
              <DescriptionOutlinedIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: location.pathname.includes("account/bill")
                    ? theme.palette.primary.main
                    : "#6a6a6a",
                }}
              />
              <Box p={1} />
              <Text m={0}>Mes factures Cofidoc</Text>
            </NavLink>
            <Box height="1px" width="100%" bgcolor="#d5d5d5" />
            {/* <NavLink onClick={() => navigationToWithPeriod("/app/tools")} selected={location.pathname.includes("tools")}>
              <DescriptionOutlinedIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: location.pathname.includes("tools") ? theme.palette.primary.main : "#6a6a6a",
                }}
              />
              <Box p={1} />
              <Text m={0}>Mes outils</Text>
            </NavLink>
            <Box height="1px" width="100%" bgcolor="#d5d5d5" /> */}
          </Box>
        </Box>
        <Box
          sx={{
            width: 250,
            height: "20%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Profile toggleDrawer={toggleDrawer(false)} />
        </Box>
      </Drawer>
    </Box>
  );
};

function Profile({ toggleDrawer }: any) {
  const { app }: any = useMongoContext();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const navigationProfile = () => {
    toggleDrawer(false);
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = queryString.stringify(
      { period: period.getTime() },
      { arrayFormat: "index" }
    );
    navigate(`/profile?${to}`);
  };

  return (
    <Box justifyContent="center" alignItems="center" flexDirection="column">
      <Box
        justifyContent="center"
        alignItems="center"
        pb={4}
        onClick={navigationProfile}
        style={{ cursor: "pointer" }}
      >
        <Text
          textAlign="center"
          fontSize="12px"
          fontWeight={location.pathname.includes("profile") ? "700" : "400"}
          color={
            location.pathname.includes("profile")
              ? theme.palette.primary.main
              : ""
          }
        >
          Mes informations
        </Text>
      </Box>
      <Box
        justifyContent="center"
        alignItems="center"
        pb={4}
        style={{ cursor: "pointer" }}
      >
        <Text
          textAlign="center"
          fontSize="12px"
          onClick={() => setOpenModal(true)}
          style={{ textDecoration: "underline" }}
        >
          Contacter mon agence
        </Text>
        <RenderModal onClose={() => setOpenModal(false)} open={openModal} />
      </Box>
      <Button
        onClick={async () => {
          await app?.currentUser?.logOut();
          navigate("/login");
          setIdToken("");
          setAccessToken("");
          setRefreshToken("");
        }}
      >
        Déconnexion
      </Button>
    </Box>
  );
}

const RenderModal = ({ open, onClose }: { open: boolean; onClose: any }) => {
  const theme = useTheme();
  return (
    <ModalMobile open={open} onClose={onClose}>
      <Box
        width="100%"
        maxWidth="100%"
        flexDirection="row"
        height="100%"
        alignItems="center"
        position="relative"
      >
        <Box width="100%" flexDirection="column" p={3} zIndex={1}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              style={{
                fontSize: 24,
                fontWeight: 500,
                color: theme.colors.primary,
                textAlign: "center",
              }}
            >
              Besoin de renseignements ?
            </Text>
            <Box p={2} />
            <Text fontSize={14} fontWeight="500" textAlign="center">
              Contactez votre agence Cofidoc la plus la plus proche.
            </Text>
            <Box p={2} />

            <Text textAlign="center" mt={2}>
              <a href="tel:0806080808" style={{ color: theme.colors.primary }}>
                08 06 08 08 08
              </a>
            </Text>
          </Box>
        </Box>
        <Box
          width="100%"
          height="100%"
          position="absolute"
          style={{ opacity: 0.3, zIndex: 0 }}
        >
          <div className="image-cofidoc-metier" />
        </Box>
        <Box
          onClick={onClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: 0,
            right: 30,
            zIndex: 2,
          }}
        >
          <Text
            color={theme.palette.primary.main}
            textAlign="center"
            fontWeight="700"
          >
            FERMER
          </Text>
        </Box>
      </Box>
    </ModalMobile>
  );
};
