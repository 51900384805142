import { CofidocBilling } from "@/api/billing/fetchCofidocBill";
import { fetchCofidocBillings } from "@/api/billing/fetchCofidocBillings";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { useQueryParams } from "@/utils/useQueryParams";
import { addMonths } from "date-fns";
import React, { useState, useEffect } from "react";

export function BillingViewWrapper({ children }) {
  const params = useQueryParams();
  const dateFilter = new Date(Number(params.period));
  const [billing, setBilling] = useState<CofidocBilling[]>();
  const { setLoading } = useSystemContext();

  async function getData() {
    try {
      setLoading(true);
      const b = await fetchCofidocBillings({
        body: JSON.stringify({
          dateStart: dateFilter,
          dateEnd: addMonths(dateFilter, 1),
        }),
      });

      const filteredAndSortedBills = b.filter(
        (bill) =>
          bill.date.getMonth() === dateFilter.getMonth() &&
          bill.date.getFullYear() === dateFilter.getFullYear()
      );
      setBilling(b);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (billing?.length) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.period]);

  useEffect(() => {
    if (!billing?.length) {
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, []);

  return <>{React.cloneElement(children, { billing })}</>;
}
