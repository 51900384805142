import { ApplicationType } from "@/types/common";
import { fetchAPI } from "@/utils/fetchAPI";

export type ProductName =
  | "Forfait avec Engagement"
  | "Gestion+ avec Engagement"
  | "Saisie PREMIER"
  | "Gestion+"
  | "eCofiCoffre";

export type CofidocHistory = {
  date?: Date;
  turnover?: number;
  nbReplacing?: number;
  monthlyTurnoverMin?: number;
  monthlyTurnoverMax?: number;
  productName?: string;
};

export async function fetchCofidocHistory() {
  const res = await fetchAPI({
    url: `/account/history`,
    options: {
      method: "POST",
    },
  });

  return res;
}
