import { useSystemContext } from "@/provider/common/SystemProvider";
import { Box } from "./Box";
import { useTheme } from "@mui/material";

export function NavLink({ onClick, children, selected }: any) {
  const theme = useTheme();
  const { isMobile } = useSystemContext();
  return (
    <>
      <Box
        onClick={onClick}
        minHeight={isMobile ? "75px" : "90px"}
        width="100%"
        sx={{
          borderLeft: "solid 4px",
          borderLeftColor: selected
            ? theme.palette.primary.main
            : "transparent",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: `${theme.palette.primary.light}20`,
          },
        }}
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        position="relative"
      >
        {children}
      </Box>
    </>
  );
}
