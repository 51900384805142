import { fetchAPI } from "@/utils/fetchAPI";

export type ProductName =
  | "Forfait avec Engagement"
  | "Gestion+ avec Engagement"
  | "Saisie PREMIER"
  | "Gestion+"
  | "eCofiCoffre";

export type CofidocContract = {
  productName?: ProductName;
  productBase?: number;
  percentage?: number;
  description?: string;
  active?: boolean;
  subscriptionDate?: Date;
  terminationDate?: Date;
  commitmentDuration?: number;
  annualTurnover?: number;
  annualTurnoverMin?: number;
  annualTurnoverMax?: number;
  monthlyTurnoverMin?: number;
  monthlyTurnoverMax?: number;
};

export async function fetchCofidocContracts() {
  try {
    const res = await fetchAPI({
      url: `/account/contracts`,
      options: {
        method: "POST",
      },
    });

    return res;
  } catch (error) {
    throw new Error();
  }
}
