import { useTheme } from "@mui/material";
import MaterialBox, { BoxProps } from "@mui/material/Box";

export function Text(
  props: BoxProps & { children?: any; style?: React.CSSProperties }
) {
  const theme = useTheme();
  return (
    <MaterialBox
      fontSize={"14px"}
      component="p"
      m={0}
      fontFamily="Nunito"
      color={theme.colors.text}
      {...props}
    />
  );
}
