import { ApplicationType } from "@/types/common";
import { fetchAPI } from "@/utils/fetchAPI";

type FetchRefreshAccessToken = {
  refreshToken: string;
  application: ApplicationType;
};

export async function fetchRefreshAccessTokenNurse({
  refreshToken,
  application,
}: FetchRefreshAccessToken) {
  try {
    return fetchAPI({
      url: `/${application}/auth/refreshToken`,
      options: {
        method: "POST",
        body: JSON.stringify({ refreshToken }),
      },
      application,
    });
  } catch (error) {
    throw new Error();
  }
}
