import {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  setIdToken,
} from "./localStorage";
import { fetchRefreshAccessTokenNurse } from "@/api/nurse/fetchRefreshAccessToken";
import { fetchRefreshAccessTokenTaxi } from "@/api/taxi/fetchRefreshAccessToken";
import { ApplicationType } from "@/types/common";
import { UpdatePasswordError } from "./UpdatePasswordError";
import { fetchAPI } from "./fetchAPI";

let count = 0;

type FetchProps = {
  url: string;
  options: RequestInit;
  application?: ApplicationType;
};

export const fetchAPITaxi = async ({
  url,
  options,
  application,
}: FetchProps) => {
  console.log("=>  url:", url);
  const token = getAccessToken();
  let baseURL = process.env.REACT_APP_API_URL_TAXI;

  const response = await fetch(`${baseURL}${url}`, {
    headers: {
      ...(options.headers || {}),
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...options,
  });

  if (!response) {
    throw new Error("server_error");
  }

  const parsedResponse = await response.json();

  if (response.status === 400) {
    throw new Error("server_error");
  }

  if (response.status === 401 && parsedResponse.errorCode === 13100) {
    console.log("in throw error");
    throw new UpdatePasswordError(parsedResponse?.code);
  }
  if (parsedResponse?.message === "Invalid token") {
    console.log("Here");

    setAccessToken("");
    setIdToken("");
    window.location.replace("/login");
    throw new Error("session_expired");
  }
  if (
    response.status === 401 &&
    (parsedResponse?.message === "The incoming token has expired" ||
      parsedResponse?.message === "Unauthorized")
  ) {
    if (count >= 3) {
      setAccessToken("");
      setIdToken("");
      window.location.replace("/login");

      throw new Error("session_expired");
    }

    const refreshToken = getRefreshToken();
    console.log("=>  refreshToken:", refreshToken);
    let result = null;

    if (application === "taxi") {
      result = await fetchRefreshAccessTokenTaxi({ refreshToken });
    }
    if (application === "mobility") {
      result = await fetchRefreshAccessTokenNurse({
        refreshToken,
        application,
      });
    }

    if (result?.status === "400" || result?.status === "401") {
      console.log("=>  result?.status: ici ", result?.status);
      setAccessToken("");
      setIdToken("");
      window.location.replace("/login");
    }
    setAccessToken(result.accessToken);
    setRefreshToken(result.refresh);
    count += 1;
    return fetchAPITaxi({ url, options });
  }

  if (response.status > 206) {
    throw new Error(parsedResponse?.message);
  }

  return parsedResponse;
};
