import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
import { PrivateRoute } from "../components/common/PrivateRoute";
import { PageError } from "../components/common/PageError";
import { Root } from "@/routes";

//  specific imports
import Profile from "@/routes/app/profile";
import AccountView from "@/routes/app/account";
import ToolsAppsView from "@/routes/app/tools/mobility";
import TaxiToolsAppsView from "@/routes/app/tools/taxi";

import ToolsConnexionView from "@/routes/app/tools/connexion";
import ToolsCoficoffreView from "@/routes/app/tools/coficoffre";
import ForgotPasswordView from "@/routes/auth/password-forgotten";
import HomeView from "@/routes/app/home";
import LoginView from "@/routes/auth/login";
import App from "@/routes/app";
// Mobile
import HomeMobileView from "@/routes/app/mobile/home";
import AccountBillMobileView from "@/routes/app/mobile/account/bill";
import AccountRefundMobileView from "@/routes/app/mobile/account/refound";
import AccountReportMobileView from "@/routes/app/mobile/account/report";
import ProfileMobileView from "@/routes/app/mobile/profile";
import ToolsMobileView from "@/routes/app/mobile/tools";

// Taxi specific imports
import { UpdatePassword } from "@/routes/auth/password-update";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { PageLoader } from "@/components/common/PageLoader";
// Mobile

export function AppRouter() {
  const { isMobile, loading } = useSystemContext();
  console.log({ loading, isMobile });
  return <>{isMobile ? <Mobile /> : <Web />}</>;
}

const Web = () => {
  const allRoutes = [
    {
      path: "/",
      element: <Root />,
      children: [
        { path: "login", element: <LoginView /> },
        { path: "create-password", element: <UpdatePassword /> },
        { path: "forgot-password", element: <ForgotPasswordView /> },
        {
          path: "/",
          element: <App />,
          children: [
            {
              path: "home",
              element: <PrivateRoute Component={HomeView} />,
            },
            {
              path: "account",
              element: <PrivateRoute Component={AccountView} />,
            },
            {
              path: "tools/taxi/",
              element: <PrivateRoute Component={TaxiToolsAppsView} />,
            },
            {
              path: "tools/mobility/",
              element: <PrivateRoute Component={ToolsAppsView} />,
            },
            {
              path: "tools/connexion",
              element: <PrivateRoute Component={ToolsConnexionView} />,
            },
            {
              path: "tools/coficoffre",
              element: <PrivateRoute Component={ToolsCoficoffreView} />,
            },
            {
              path: "profile",
              element: <PrivateRoute Component={Profile} />,
            },
          ],
        },
      ],
    },
  ];

  let commons = [];
  let children = [...commons, ...allRoutes];

  const routes = [{ path: "/", element: <Root />, errorElement: <PageError />, children }];

  const BrowserRouter = createBrowserRouter(routes);

  return <RouterProvider router={BrowserRouter} />;
};

const Mobile = () => {
  const allRoutes = [
    {
      path: "/",
      element: <Root />,
      children: [
        { path: "login", element: <LoginView /> },
        { path: "create-password", element: <UpdatePassword /> },
        { path: "forgot-password", element: <ForgotPasswordView /> },
        {
          path: "/",
          element: <App />,
          children: [
            {
              path: "home",
              element: <PrivateRoute Component={HomeMobileView} />,
            },
            {
              path: "account/bill",
              element: <PrivateRoute Component={AccountBillMobileView} />,
            },
            {
              path: "account/refund",
              element: <PrivateRoute Component={AccountRefundMobileView} />,
            },
            {
              path: "account/report",
              element: <PrivateRoute Component={AccountReportMobileView} />,
            },
            {
              path: "profile",
              element: <PrivateRoute Component={ProfileMobileView} />,
            },
            {
              path: "tools",
              element: <PrivateRoute Component={ToolsMobileView} />,
            },
          ],
        },
      ],
    },
  ];

  let commons = [];
  let children = [...commons, ...allRoutes];

  const routes = [{ path: "/", element: <Root />, errorElement: <PageError />, children }];

  const BrowserRouter = createBrowserRouter(routes);

  return <RouterProvider router={BrowserRouter} />;
};
