import { fetchCofidocReport } from "@/api/billing/fetchCofidocReport";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { Report } from "@/types/nurse";
import { useQueryParams } from "@/utils/useQueryParams";
import { addMonths, parseISO, isValid, format } from "date-fns";
import React, { useState, useEffect } from "react";

export function ReportVieWrapper({ children }) {
  const params = useQueryParams();
  const { setLoading } = useSystemContext();
  const [reports, setReports] = useState<Report[]>([]);

  let initialDate = params.period
    ? new Date(Number(params.period))
    : new Date();
  const dateFilter = new Date(
    `${initialDate.getMonth() + 1}/01/${initialDate.getFullYear()}`
  );
  // Get refunds function
  async function getFilteredReport() {
    const report = await fetchCofidocReport({
      body: JSON.stringify({
        dateStart: dateFilter,
        dateEnd: addMonths(dateFilter, 1),
      }),
    });

    return report?.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  // Get data function
  async function getData() {
    try {
      const dateFilter = params.period
        ? new Date(Number(params.period))
        : new Date();
      setLoading(true);
      const report = await getFilteredReport();
      // Filter report for the selected month and for the selected year
      const filteredAndSortedRefunds = report.filter(
        (refund) =>
          refund.date.getMonth() === dateFilter.getMonth() &&
          refund.date.getFullYear() === dateFilter.getFullYear()
      );

      setReports(filteredAndSortedRefunds);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.period]);

  // Format date function
  const formatDate = (dateStr: number | Date): string => {
    let date = dateStr;
    if (typeof dateStr === "string") {
      date = parseISO(dateStr);
    }
    return isValid(date) ? format(date, "dd/MM/yyyy") : "Date invalide";
  };

  return <>{React.cloneElement(children, { formatDate, reports })}</>;
}
