import { Outlet } from "react-router-dom";
import { Box } from "@/ui";
import { LeftNavBar as LeftNavBarNusre } from "@/components/nurse/AppShell";
import { LeftNavBar as LeftNavBarTaxi } from "@/components/taxi/AppShell";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { BurgerMenu } from "@/components/common/mobile/Menu";
import {
  CofidocContract,
  fetchCofidocContracts,
} from "@/api/billing/fetchCofidocContracts";
import { useState, useEffect, useRef } from "react";
import { AppProvider } from "@/provider/common/AppProvider";
import {
  CofidocHistory,
  fetchCofidocHistory,
} from "@/api/billing/fetchCofidocHistory";
import { CofidocBilling } from "@/api/billing/fetchCofidocBill";
import { orderBy } from "lodash";
import { fetchCofidocBillings } from "@/api/billing/fetchCofidocBillings";
import { addMonths } from "date-fns";

export default function App() {
  const { isMobile, setLoading } = useSystemContext();

  const [periodeStart, setPeriodeStart] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [periodeEnd, setPeriodeEnd] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const [contract, setContract] = useState<CofidocContract>(null);
  const [optionGestion, setOptionGestion] = useState<CofidocContract>(null);
  const [optionECoficoffre, setOptionECoficoffre] =
    useState<CofidocContract>(null);
  const [lastHistory, setHistory] = useState<CofidocHistory>();
  const [relatedLastBill, setBilling] = useState<CofidocBilling>();

  async function getMainData() {
    try {
      console.log("get main data");
      setLoading(true);
      const [contracts, historyRes] = await Promise.all([
        fetchCofidocContracts(),
        fetchCofidocHistory(),
      ]);

      const findedContract = contracts.find(
        (c) =>
          ["Liberté", "Fidélité", "Abo", "Forfait avec Engagement"].includes(
            c.productName
          ) && c.active
      );
      const findedGestion = contracts.find(
        (c) =>
          ["Gestion+", "Gestion+ avec Engagement"].includes(c.productName) &&
          c.active
      );
      const findedCoficoffre = contracts.find(
        (c) => c.productName === "eCofiCoffre" && c.active
      );
      const h = orderBy(historyRes, "date", "desc")[0];
      setHistory(h);
      const lastHistoryDate = new Date(h?.date);
      const billingRes = await fetchCofidocBillings({
        body: JSON.stringify({
          start: lastHistoryDate,
          end: addMonths(lastHistoryDate, 1),
        }),
      });
      const r = orderBy(billingRes, "date", "desc").find((b) => {
        const billDate = new Date(b.date);
        if (
          lastHistoryDate?.getMonth() === billDate?.getMonth() &&
          lastHistoryDate?.getFullYear() === billDate?.getFullYear()
        ) {
          return b;
        }
        return undefined;
      });

      setBilling(r);

      setContract(findedContract);
      setOptionGestion(findedGestion);
      setOptionECoficoffre(findedCoficoffre);
    } catch (error) {
      console.log("error :", error);
    } finally {
      console.log("set loading false");
      setLoading(false);
    }
  }

  useEffect(() => {
    getMainData();
  }, []);

  return (
    <>
      <AppProvider
        value={{
          periodeStart,
          periodeEnd,
          setPeriodeStart,
          setPeriodeEnd,
          contract,
          lastHistory,
          relatedLastBill,
          optionGestion,
          optionECoficoffre,
        }}
      >
        {isMobile ? <RenderMobile /> : <RenderWeb />}
      </AppProvider>
    </>
  );
}
function RenderWeb() {
  const { getUser } = useSystemContext();

  let user = getUser();

  const isTaxi = user?.codeSpecialite === "90";
  return (
    <Box
      flexDirection="column"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      width="100vw"
    >
      <div className="background-mountain-cofidoc" />
      <div className="background-cloud height40" />
      <div className="background-nav-bar" />
      <Box flexDirection="row" width="100%" height="100%" alignItems="center">
        {isTaxi ? <LeftNavBarTaxi /> : <LeftNavBarNusre />}

        <Box
          flexDirection="column"
          width="calc(100% - 170px)"
          height="100%"
          alignItems="center"
          className="overBg"
          style={{ overflowY: "scroll" }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

function RenderMobile() {
  return (
    <Box
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <div className="background-mountain-cofidoc" />
      <div className="background-cloud height40" />
      <div className="background-over" />
      <BurgerMenu />
      <Box
        flexDirection="column"
        width="100%"
        height="100%"
        alignItems="center"
        className="overBg"
      >
        <Outlet />
      </Box>
    </Box>
  );
}
