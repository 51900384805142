import DownloadRefundPDF from "@/components/common/RefundPDF";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Box, Text, Card, Accordion } from "@/ui";
import { useQueryParams } from "@/utils/useQueryParams";
import { useSystemContext } from "@/provider/common/SystemProvider";

export function RefundViewCpnWeb({ refunds, formatDate, pluralize }: any) {
  const params = useQueryParams();
  const { getUser } = useSystemContext();

  return (
    <>
      <Box m={2} />
      {refunds?.length > 0 ? (
        <>
          <Card
            flexDirection="column"
            p={0}
            display="flex"
            style={{ marginBottom: "16px", width: "100%" }}
          >
            {/* Présentation montant et informations */}
            <Box
              flexDirection="row"
              justifyContent="space-evenly"
              alignItems="center"
              p={3}
              m={2}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                flexShrink={0}
                p={2}
                m={2}
              >
                <Text fontSize={26} fontWeight={800}>
                  {refunds.reduce((acc, refund) => {
                    const total = refund?.total ? parseFloat(refund.total) : 0;
                    let number = Number(acc + total).toFixed(2);
                    return Number(number);
                  }, 0)}{" "}
                  €
                </Text>
                <Text fontSize={16}>
                  {refunds.length}{" "}
                  {pluralize(refunds.length, "virement", "virements")} -{" "}
                  {
                    refunds.filter(
                      (refund, index, self) =>
                        self.findIndex(
                          (r) => r.nomOrganisme === refund.nomOrganisme
                        ) === index
                    ).length
                  }{" "}
                  {pluralize(
                    refunds.filter(
                      (refund, index, self) =>
                        self.findIndex(
                          (r) => r.nomOrganisme === refund.nomOrganisme
                        ) === index
                    ).length,
                    "organisme",
                    "organismes"
                  )}
                </Text>
              </Box>

              {/* Bouton pour télécharger */}
              <DownloadRefundPDF
                data={refunds}
                period={params.period}
                currentUser={getUser()}
              />
            </Box>
          </Card>
          {refunds.map((refund, index) => (
            <Box
              width="100%"
              py={2}
              justifyContent="center"
              alignItems="center"
            >
              <Accordion
                width="100%"
                key={refund._id || index}
                title={`${formatDate(refund.date)} - ${
                  refund.nomOrganisme
                } - ${parseFloat(refund.total).toFixed(2)} €`}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Facture</TableCell>
                      <TableCell>Patient</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Payé</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refund.details.map((detail, detailIndex) => (
                      <TableRow
                        key={detailIndex}
                        style={{
                          backgroundColor:
                            detailIndex % 2 === 0 ? "#fff" : "#f3e7e765",
                        }}
                      >
                        <TableCell>{detail.numFacture}</TableCell>
                        <TableCell>{detail.nomPatient}</TableCell>
                        <TableCell>
                          {formatDate(detail.debut)} - {formatDate(detail.fin)}
                        </TableCell>
                        <TableCell>
                          {parseFloat(detail.paye).toFixed(2)} €
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Accordion>
            </Box>
          ))}
        </>
      ) : (
        <Card flexDirection="column" display="flex" style={{ width: "100%" }}>
          <Box
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            p={2}
            m={2}
          >
            <Text fontSize={18} fontWeight={500}>
              Aucun remboursement pour cette période
            </Text>
          </Box>
        </Card>
      )}
    </>
  );
}
