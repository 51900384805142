import { InvoiceItem } from "@/components/common/home/InvoiceItem";
import { Box, Text } from "@/ui";
import { useTheme } from "@mui/material";
import { groupBy } from "lodash";

export function BillingViewCpnWeb({ billing }: any) {
  const theme = useTheme();
  const grouped = groupBy(billing, (b) => b.date?.getFullYear());
  const keys = Object.keys(grouped).sort().reverse();

  return (
    <Box width="100%" flexDirection="column" height="100%" p={5} style={{ overflowY: "scroll", overflowX: "hidden" }}>
      {keys.map((key) => {
        const bills = grouped[key];
        return (
          <>
            <Box width="100%" borderBottom="1px solid" borderColor={theme.palette.primary.light}>
              <Text color={theme.palette.primary.main} fontSize={70} fontFamily="Caveat" fontWeight="600">
                {key}
              </Text>
            </Box>
            <Box flexDirection="row" flexWrap="wrap">
              {bills?.map((invoice) => {
                return <InvoiceItem key={invoice?.id?.toString()} invoice={invoice} />;
              })}
            </Box>
          </>
        );
      })}
    </Box>
  );
}
