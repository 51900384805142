import { useEffect, useState } from "react";
import { Box, Text } from "@/ui";
import BaseTabs from "@mui/material/Tabs";
import BaseTab from "@mui/material/Tab";
import { styled } from "@mui/system";
import { FilterPeriodBox, ReportView, RefundView, BillingView } from "@/components/common/account/AccountViews";
import { CustomTabPanel } from "../CustomTabPanel";
import { useQueryParams } from "@/utils/useQueryParams";

export default function Account() {
  const params = useQueryParams();
  const [value, setValue] = useState(Number(params?.tab) || 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box flexDirection="column" width="100%" height="100%" className="overBg" style={{ overflowY: "scroll" }} p={4} py={5} pl={0}>
      <Box
        width="100%"
        height="100%"
        flexDirection="column"
        alignItems="center"
        bgcolor="#fafafaec"
        boxShadow="7px 7px 12px 0px #c3c3c3"
        borderRadius={5}
      >
        <Box width="100%" flexDirection="column" p={5} pb={0} height="90px">
          <Text fontSize={35} fontWeight="800">
            Gestion Cofidoc,
          </Text>
          <Text fontSize={18} fontWeight="300">
            Nos Cofidettes à votre service !
          </Text>
        </Box>
        <Box width="80%" height="calc(100% - 90px)" p={5} flexDirection="column">
          <Box>
            <Box flex="0.8" justifyContent="center" alignItems="center">
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
              >
                <Tab label="Mes comptes rendus de facturation" />
                <Tab label="Mes paiements reçus" />
                <Tab label="Mes factures Cofidoc" />
              </Tabs>
            </Box>
            <Box flex="0.2">
              <FilterPeriodBox />
            </Box>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ReportView isMobile={false} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <RefundView isMobile={false} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <BillingView isMobile={false} />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}

const Tabs = styled(BaseTabs)`
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: visible;
  height: 80px;
`;

const Tab = styled(BaseTab)(({ theme }) => ({
  color: "#000",
  cursor: "pointer",
  width: "30%",
  backgroundColor: "#fff",
  padding: "10px 12px",
  margin: "12px",
  border: "none",
  borderRadius: "50px",
  display: "flex",
  justifyContent: "center",
  boxShadow: "9px 9px 12px -7px #aaaaaa",
  textTransform: "none",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));
