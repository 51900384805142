import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./image.css";
import { SystemProvider } from "./provider/common/SystemProvider";
import reportWebVitals from "./reportWebVitals";
import "react-calendar/dist/Calendar.css";
import { AppRouter } from "./routes/Router";
import { AppThemeProvider } from "./theme/ThemeProvider";
export const LAST_CONNECTION = "LAST_CONNECTION";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // DOUBLE RENDER IN PRODUCTION ENABLE ONLY ON DEV
  // <React.StrictMode>
  <SystemProvider>
    <AppThemeProvider>
      <AppRouter />
    </AppThemeProvider>
  </SystemProvider>
  // </React.StrictMode>
);

reportWebVitals();
