import { Link, useTheme } from "@mui/material";
import { Box } from "./Box";

export function Href({
  textconfirm,
  withconfirm,
  onClick,
  children,
  href,
  target,
  ...props
}: {
  textconfirm?: string;
  withconfirm?: boolean;
  onClick?: any;
  children: any;
  href: any;
  target?: any;
}) {
  const theme = useTheme();
  return (
    <Link style={{ textDecoration: "none" }} href={href} target={target} className="cofidocHref" {...props}>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.colors.primary,
          padding: "2px 14px",
          minHeight: 28,
          borderRadius: 2,
          color: "#ffffff",
          fontWeight: 400,
          textTransform: "inherit",
          "&:hover": {
            background: "#efefef",
            backgroundColor: theme.colors.light,
            color: "white",
          },
        }}
      >
        {children}
      </Box>
    </Link>
  );
}
