import { Box, Card, Text } from "@/ui";
import { useTheme } from "@mui/material";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { User as UserTaxi } from "@/types/taxi";
import { Divider } from "@mui/material";

export default function Profile() {
  const theme = useTheme();

  return (
    <>
      <Box width="100%" flexDirection="column" justifyContent="center" height="90px" borderBottom={`solid 1px ${theme.palette.primary.light}`}>
        <Text fontSize={28} fontWeight="800" paddingLeft="70px">
          <span style={{ color: theme.palette.primary.main }}>M</span>es <span style={{ color: theme.palette.primary.main }}>I</span>
          nformations
        </Text>
        <Text fontSize={16} fontWeight="300" paddingLeft="70px">
          Bienvenue dans votre espace client
        </Text>
      </Box>
      <Box p={2} width="100%">
        <Card display="flex" flexDirection="row" p={0} width="100%" maxWidth="1200px">
          <RenderProfile />
        </Card>
      </Box>
    </>
  );
}

function RenderProfile() {
  const { getUser } = useSystemContext();
  const theme = useTheme();
  let user = getUser();

  return (
    <Box p={4} flexDirection="column" width="100%">
      <Box p={2} flexDirection="column">
        <Text fontSize={16} color={theme.palette.primary.main}>
          Nom / Prénom
        </Text>
        <Text fontSize={18}>
          {user?.prenom} {user?.nom}
        </Text>
      </Box>
      <Divider />
      <Box p={2} flexDirection="column">
        <Text fontSize={16} color={theme.palette.primary.main}>
          Email
        </Text>
        <Text fontSize={18}>{user?.email}</Text>
      </Box>
      <Divider />
      <Box p={2} flexDirection="column">
        <Text fontSize={16} color={theme.palette.primary.main}>
          Finess
        </Text>
        <Text fontSize={18}>{user?.identifiant}</Text>
      </Box>
    </Box>
  );
}
