import {
  Page,
  Text,
  View,
  Svg,
  Path,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, Text as TextUI } from "@/ui";
import { format, parseISO, isValid } from "date-fns";
import { useTheme } from "@mui/material";
import { useState } from "react";
import saveAs from "file-saver";
// Créer les styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  section: {
    padding: 10,
  },
  logo: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: 10,
  },
  header: {
    fontSize: 18,
    color: "black",
    marginBottom: 5,
    textAlign: "justify",
    flexWrap: "wrap",
  },
  organismText: {
    fontSize: 12,
    textAlign: "justify",
    fontWeight: 600,
    color: "black",
    marginBottom: 5,
    padding: 5,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    borderWidth: "0.55",
    borderColor: "#e0e0e0",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: "0.55",
    borderBottomColor: "#e0e0e0",
    backgroundColor: "#fff",
  },
  tableRowEven: {
    flexDirection: "row",
    borderBottomWidth: "0.55",
    borderBottomColor: "#e0e0e0",
    backgroundColor: "#f3e7e7",
  },
  tableCellHeader: {
    flex: 1,
    borderWidth: "0.55",
    borderColor: "#e0e0e0",
    padding: 5,
    justifyContent: "center",
    backgroundColor: "#FF008D",
  },
  tableCell: {
    flex: 1,
    borderWidth: "0.55",
    borderColor: "#e0e0e0",
    padding: 5,
    justifyContent: "center",
  },
  tableCellTextHeader: {
    fontSize: 10,
    fontWeight: 600,
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
  },
  tableCellText: {
    fontSize: 9,
    color: "black",
    textAlign: "center",
  },
  textInfoTitle: {
    fontSize: 10,
    textAlign: "justify",
    fontWeight: 600,
    color: "#FF008D",
    textTransform: "uppercase",
  },
  textInfo: {
    fontSize: 10,
    textAlign: "justify",
    color: "black",
  },
  pageNumber: {
    fontSize: 8,
    textAlign: "center",
    color: "black",
  },
  footerPageNumber: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

// Format date function
const formatDate = (dateStr) => {
  let date = dateStr;
  if (typeof dateStr === "string") {
    date = parseISO(dateStr);
  }

  return isValid(date) ? format(date, "dd/MM/yyyy") : "Date invalide";
};

// Créer le document
export const RefundPDF = ({ data, period, currentUser }) => {
  return (
    <Document title="Remboursements">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={{ display: "flex", flexDirection: "row", padding: 10 }}>
            {/* Image de Cofidoc */}
            <View style={{ width: "20%" }}>
              <Svg width="112" viewBox="0 0 560.8 280.4" style={styles.logo}>
                <Path
                  fill="#FF008D"
                  d="M130.9,104.5L66,175l61.9,70.2c10.5,11.9,10.1,30.9-0.8,42.4c-5.4,5.7-12.5,8.5-19.5,8.4 c-7.1-0.1-14.1-3.2-19.4-9.1L7.7,195.5c-10.4-11.8-10.2-30.4,0.4-42l84-91.4c10.8-11.7,28.2-11.7,38.9,0 C141.7,73.8,141.7,92.8,130.9,104.5z M387,110.7c-0.4,0.3-44.1,27.7-99,37.5c-55.2,9.7-108.8-1.5-109.3-1.6 c-14.9-3.2-29.3,7.3-32.4,23.5c-3,16.2,6.6,32,21.5,35.2c1.7,0.4,28.9,6.2,66,6.7c19,0.3,40.7-0.8,62.8-4.7 c65.5-11.5,116-43.5,118.1-44.9c13.1-8.4,17.5-26.7,9.9-41C417,107.1,400.1,102.4,387,110.7z M560.8,75.9 c-2.4-7.6-7.5-13.8-14.1-17.2l-107-55.7C426-4.2,409.6,2.1,403,17.1c-6.6,14.9-0.8,32.8,12.9,39.9l81.9,42.6l-39.5,86.8 c-6.8,14.8-1.2,32.8,12.4,40.1c3.8,2,7.8,3.1,11.8,3.1c10.3,0.2,20.2-6,25.1-16.7l51.9-114.1C562.7,91.7,563.2,83.4,560.8,75.9z"
                />
              </Svg>
            </View>
            <View style={{ width: "10%" }}></View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "70%",
              }}
            >
              {/* Titre du PDF */}
              <Text style={styles.header}>
                Paiements reçus du mois de{" "}
                {format(new Date(Number(period)), "MMMM yyyy")}
              </Text>

              {/* Informations sur le client */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={styles.textInfoTitle}>Client : </Text>
                <Text style={styles.textInfo}>
                  {currentUser.prenom} {currentUser.nom}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={styles.textInfoTitle}>Identifiant : </Text>
                <Text style={styles.textInfo}>{currentUser.identifiant}</Text>
              </View>
            </View>
          </View>
        </View>

        {/* Tableau des paiements */}
        <View style={styles.section}>
          {data.map((refund, index) => (
            <View key={index} style={{ marginBottom: 15 }}>
              <Text style={styles.organismText}>
                Organisme : {refund.nomOrganisme}
              </Text>
              {/* En-tête du tableau */}
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCellHeader}>
                    <Text style={styles.tableCellTextHeader}>N° facture</Text>
                  </View>
                  <View style={styles.tableCellHeader}>
                    <Text style={styles.tableCellTextHeader}>Patient</Text>
                  </View>
                  <View style={styles.tableCellHeader}>
                    <Text style={styles.tableCellTextHeader}>Date</Text>
                  </View>
                  <View style={styles.tableCellHeader}>
                    <Text style={styles.tableCellTextHeader}>Facturé</Text>
                  </View>
                  <View style={styles.tableCellHeader}>
                    <Text style={styles.tableCellTextHeader}>Payé</Text>
                  </View>
                  {/* <View style={styles.tableCellHeader}>
                    <Text style={styles.tableCellTextHeader}>
                      Reste à payer
                    </Text>
                  </View> */}
                </View>
                {/* Lignes du tableau */}
                {refund.details.map((detail, index) => (
                  <View
                    key={detail.numFacture}
                    style={index % 2 ? styles.tableRowEven : styles.tableRow}
                  >
                    <View style={styles.tableCell}>
                      <Text style={styles.tableCellText}>
                        {detail.numFacture}
                      </Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.tableCellText}>
                        {detail.nomPatient}
                      </Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.tableCellText}>
                        {formatDate(detail.debut)} - {formatDate(detail.fin)}
                      </Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.tableCellText}>
                        {parseFloat(detail.facture).toFixed(2)} €
                      </Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.tableCellText}>
                        {parseFloat(detail.paye).toFixed(2)} €
                      </Text>
                    </View>
                    {/* <View style={styles.tableCell}>
                      <Text style={styles.tableCellText}>
                        {parseFloat(detail.reste).toFixed(2)} €
                      </Text>
                    </View> */}
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>

        <View style={styles.footerPageNumber} fixed>
          <Text style={styles.pageNumber}>Cofidoc</Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} / ${totalPages}`
            }
          />
          <Text style={styles.pageNumber}>
            Le {format(new Date(), "dd/MM/yyyy")} à{" "}
            {format(new Date(), "HH:mm")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const DownloadRefundPDF = ({ data, period, currentUser }) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  async function makePdf() {
    setLoading(true);
    let blob = await pdf(
      <RefundPDF
        key={period}
        data={data}
        period={period}
        currentUser={currentUser}
      />
    ).toBlob();

    saveAs(
      blob,
      `remboursements-${format(new Date(Number(period)), "MM-yyyy")}.pdf`
    );
    setLoading(false);
  }

  return (
    <Button disabled={loading} onClick={makePdf}>
      <DownloadIcon
        fontSize="small"
        style={{ color: theme.colors.primary as any }}
      />
      <TextUI m={0} style={{ marginLeft: "4px" }}>
        {loading ? "Chargement du document..." : "Télécharger le PDF"}
      </TextUI>
    </Button>
  );
};

export default DownloadRefundPDF;
