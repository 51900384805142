import { fetchAPI } from "@/utils/fetchAPI";

export type ProductName =
  | "Forfait avec Engagement"
  | "Gestion+ avec Engagement"
  | "Saisie PREMIER"
  | "Gestion+"
  | "eCofiCoffre";

export type CofidocBilling = {
  id?: number;
  date?: Date;
  reference?: string;
  amount?: string;
};

export async function fetchCofidocBill({ application, body }) {
  try {
    const res = await fetchAPI({
      url: `/account/billing`,
      options: {
        method: "POST",
        body,
      },
      application,
    });

    return res;
  } catch (error) {
    throw new Error();
  }
}
