import { useAppContext } from "@/provider/common/AppProvider";
import { Box, Card, Text, Modal, Href } from "@/ui";
import { useState } from "react";
import { Skeleton, useTheme } from "@mui/material";
import { CofidocContract } from "@/api/billing/fetchCofidocContracts";
import { format } from "date-fns";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AddModeratorIcon from "@mui/icons-material/AddModerator";

const Contracts = () => {
  const theme = useTheme();
  const { contract, optionGestion } = useAppContext();
  console.log("=>  optionGestion:", optionGestion);
  console.log("=>  contract:", contract);

  return (
    <>
      <ContractCard
        contract={contract}
        theme={theme}
        icon={LocalOfferIcon}
        title={contract?.productName}
        showIcon
      />
      <Box flex={0.02} />
      <ContractCard
        contract={optionGestion}
        theme={theme}
        icon={AddModeratorIcon}
        title="Option Tranquillité"
      />
    </>
  );
};

const ContractCard = ({ contract, theme, title, showIcon }: any) => {
  if (contract === null) {
    return (
      <Box
        flex={0.35}
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Card
          bgcolor={contract ? theme.colors.white : theme.palette.grey[100]}
          flexDirection="column"
          position="relative"
          height="100%"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box width="100%" justifyContent="center" alignItems="center" p={2}>
            <Skeleton variant="rounded" width={210} height={20} />
          </Box>
          <Box width="100%" p={1}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={20}
            />
          </Box>
          <Box width="100%" p={1}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={20}
            />
          </Box>
          <Box width="100%" p={1}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={20}
            />
          </Box>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      flex={0.35}
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Card
        bgcolor={contract ? theme.colors.white : theme.palette.grey[100]}
        display="flex"
        flexDirection="column"
        position="relative"
        height="100%"
        width="100%"
        alignItems="center"
      >
        <Box alignItems="center" justifyContent="center" p={4} pb={0}>
          <Text fontSize={20} pl={2} fontWeight="800">
            {(contract?.productName.includes("Liberté") ||
              contract?.productName.includes("Fidélité")) &&
              "Forfait "}
            {title}
          </Text>
        </Box>
        {showIcon && (
          <Box
            bgcolor={theme.palette.primary.main}
            width="50px"
            height="50px"
            borderRadius="50px"
            position="absolute"
            top="-15px"
            right="-15px"
            justifyContent="center"
            alignItems="center"
          >
            <div
              className="white-smile"
              style={{ width: "28px", height: "28px" }}
            />
          </Box>
        )}
        <Box flexDirection="column" justifyContent="center" mb={1} width="80%">
          {contract?.productName.includes("Liberté") && (
            <>
              <Text
                color={theme.palette.grey[500]}
                fontSize={14}
                fontWeight="600"
              >
                SANS ENGAGEMENT
              </Text>
              <Text
                color={theme.palette.grey[500]}
                fontSize={14}
                fontWeight="600"
              >
                COFFRE-FORT NUMERIQUE
              </Text>
              <Box flexDirection="row">
                <Text fontWeight="600" mr={2}>
                  Mon taux:
                </Text>
                <Text>{contract?.percentage}% du chiffre d'affaire traité</Text>
              </Box>
            </>
          )}
          {contract?.productName.includes("Fidélité") && (
            <>
              <Text
                color={theme.palette.grey[500]}
                fontSize={14}
                fontWeight="600"
              >
                ABONNEMENT {contract.commitmentDuration} MOIS
              </Text>
              <Text
                color={theme.palette.grey[500]}
                fontSize={14}
                fontWeight="600"
              >
                COFFRE-FORT NUMERIQUE
              </Text>
              <Box flexDirection="row">
                <Text fontWeight="600" mr={2}>
                  Mensualité:
                </Text>
                <Text>{contract?.productBase}€ / mois</Text>
              </Box>
            </>
          )}
          {!!contract?.subscriptionDate && (
            <Box flexDirection="row">
              <Text fontWeight="600" mr={2}>
                Date de souscription:
              </Text>
              <Text>
                {format(new Date(contract?.subscriptionDate), "dd MMMM yyyy")}
              </Text>
            </Box>
          )}

          {!contract && title?.includes("Option") && (
            <Text
              color={theme.palette.grey[500]}
              fontSize={12}
              fontWeight="700"
              textAlign="center"
            >
              Optez pour la tranquillité : profitez du suivi bancaire et de la
              relance d’impayés ! Contactez votre gestionnaire pour en
              bénéficier.
            </Text>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default Contracts;
