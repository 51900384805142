import { Box, Text } from "@/ui";
import { useTheme } from "@mui/material";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useSystemContext } from "@/provider/common/SystemProvider";
import {
  CofidocBilling,
  fetchCofidocBill,
} from "@/api/billing/fetchCofidocBill";

export const InvoiceItem = ({ invoice }: { invoice: CofidocBilling }) => {
  const theme = useTheme();
  const { application } = useSystemContext();

  function base64ToBlob(base64, mimeType) {
    // Decode the Base64 string
    const binaryString = atob(base64);

    // Create an array to hold the decoded binary data
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);

    // Populate the array with binary data
    for (let i = 0; i < binaryLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create and return the Blob
    return new Blob([bytes], { type: mimeType });
  }

  const downloadInvoicesByMonth = async (invoice: CofidocBilling) => {
    try {
      const res = await fetchCofidocBill({
        application,
        body: JSON.stringify({ billId: invoice.id?.toString() }),
      });
      const fileName = `Facture_${format(invoice?.date, "dd_MM_yyyy")}.pdf`;
      const mimeType = "application/pdf";
      saveAs(base64ToBlob(res?.base64, mimeType), fileName);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };

  return (
    <Box
      p={1}
      m={1}
      flexDirection="column"
      position="relative"
      alignItems="center"
      justifyContent="center"
      style={{ cursor: "pointer" }}
      onClick={() => downloadInvoicesByMonth(invoice)}
    >
      <InsertDriveFileIcon
        style={{
          position: "absolute",
          zIndex: 0,
          color: theme.palette.grey[200],
          fontSize: 150,
        }}
      />
      <InsertDriveFileIcon
        style={{ zIndex: 1, color: theme.palette.grey[100], fontSize: 140 }}
      />
      <Box
        zIndex={1}
        p={2}
        style={{ position: "absolute", zIndex: 2 }}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <DownloadIcon
          style={{ color: `${theme.palette.primary.main}70`, fontSize: 35 }}
        />
        <Text fontSize={14} fontWeight="500" width="85px" textAlign="center">
          {format(invoice?.date, "MMMM yyyy")}
        </Text>
      </Box>
    </Box>
  );
};
