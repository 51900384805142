import { useSystemContext } from "@/provider/common/SystemProvider";
import { Box, Card, Text } from "@/ui";
import { useTheme } from "@mui/material";

export default function Profile() {
  const theme = useTheme();
  const { application } = useSystemContext();
  return (
    <>
      <Box
        width="100%"
        flexDirection="column"
        justifyContent="center"
        height="90px"
        borderBottom={`solid 1px ${theme.palette.primary.light}`}
      >
        <Text fontSize={28} fontWeight="800" paddingLeft="70px">
          <span style={{ color: theme.palette.primary.main }}>M</span>es{" "}
          <span style={{ color: theme.palette.primary.main }}>O</span>utils
        </Text>
        <Text fontSize={16} fontWeight="300" paddingLeft="70px">
          Consulter mes outils Cofidoc
        </Text>
      </Box>
      <Box p={2} width="100%">
        <Card
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="1200px"
          p={5}
        >
          <Text fontSize={18} p={2}>
            Pour accéder à vos outils Cofidoc, merci d'accéder à la version web
            sur tablette ou ordinateur.
          </Text>
          {application === "mobility" && (
            <>
              <Text fontSize={18} p={2}>
                La version tablette ou ordinateur vous donne accès aux données
                de votre application mobile{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  Mobility
                </span>
                , à la{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  connexion à distance
                </span>{" "}
                ou encore à vos{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  données sécurisées
                </span>
                .
              </Text>
            </>
          )}
          {application === "taxi" && (
            <>
              <Text fontSize={18} p={2}>
                La version tablette ou ordinateur vous donne accès aux données
                de votre application mobile{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  Cofidoc Taxi
                </span>
                , à la ou encore à vos{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  données sécurisées
                </span>
                .
              </Text>
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
