import { fetchAPI } from "@/utils/fetchAPI";
import { fetchAPIMobility } from "@/utils/fetchAPIMobility";

export type ProductName =
  | "Forfait avec Engagement"
  | "Gestion+ avec Engagement"
  | "Saisie PREMIER"
  | "Gestion+"
  | "eCofiCoffre";

export async function fetchOffices({ application }) {
  try {
    const res = await fetchAPIMobility({
      url: `/account/offices`,
      options: {
        method: "POST",
      },
      application,
    });

    return res.map((c) => ({ ...c, date: new Date(c.date) }));
  } catch (error) {
    throw new Error();
  }
}
