import { ApplicationType } from "@/types/common";
import { fetchAPI } from "@/utils/fetchAPI";

type FetchForgotPassword = {
  email: string;
};

type FetchUpdatePassword = {
  email: string;
  newPassword: string;
  oldPassword: string;
};

type FetchResetPassword = {
  email: string;
  code: string;
  password: string;
};

export async function fetchForgotPassword({ email }: FetchForgotPassword) {
  try {
    const res = await fetchAPI({
      url: `/account/forgotPassword`,
      options: {
        method: "POST",
        body: JSON.stringify({ email }),
      },
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}

export async function fetchUpdatePassword({
  email,
  newPassword,
  oldPassword,
}: FetchUpdatePassword) {
  try {
    const res = await fetchAPI({
      url: `/account/update-password`,
      options: {
        method: "POST",
        body: JSON.stringify({ email, newPassword, oldPassword }),
      },
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}

export async function fetchResetPassword({
  email,
  code,
  password,
}: FetchResetPassword) {
  try {
    const res = await fetchAPI({
      url: `/account/changePassword`,
      options: {
        method: "POST",
        body: JSON.stringify({ email, code, password }),
      },
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}
