import { Formik, Form } from "formik";
import { Box, Text, Button, Link } from "../../../ui";
import { Card } from "../../../ui/Card";
import { TextField } from "../../../ui/field/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fetchUpdatePassword } from "@/api/billing/fetchAuth";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { useTheme } from "@mui/material";

export function UpdatePassword() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setLoading, isMobile } = useSystemContext();
  const { state } = useLocation();
  const theme = useTheme();

  return (
    <Box
      height="100vh"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <div className="background-cloud height40" />
      <div className="background-over" />
      <div className="background-mountain-cofidoc" />
      <Box
        display="flex"
        p={2}
        alignItems="center"
        justifyContent="center"
        zIndex={2}
        position="absolute"
        top={20}
        left={20}
      >
        <div className="background-logo" />
      </Box>
      <Box
        width="100%"
        style={{
          position: "relative",
          zIndex: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          width={isMobile ? "80%" : "550px"}
        >
          <Formik
            initialValues={{ newPassword: "" }}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                setError(null);
                await fetchUpdatePassword({
                  email: state.email,
                  oldPassword: state.password as string,
                  newPassword: values.newPassword,
                });

                navigate("/login");
              } catch (e) {
                console.log("error", e);
                setError(
                  "Une erreur est survenue merci de Contactez votre gestionnaire"
                );
              } finally {
                setLoading(false);
              }
            }}
          >
            <Form
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display="flex" width="100%">
                <Link onClick={() => navigate("/welcome")}>
                  <ArrowBackIcon fontSize="small" />
                  <Box pr={1} />
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >
                    Retour au portail de connexion
                  </Text>
                </Link>
              </Box>
              <Box style={{ margin: 20 }}>
                <Text
                  fontSize={20}
                  fontWeight={500}
                  textAlign="center"
                  color={theme.colors.primary}
                >
                  Créez votre mot de passe
                </Text>
              </Box>
              <Box width="80%">
                <TextField
                  type="password"
                  label="Nouveau mot de passe"
                  name="newPassword"
                  width="100%"
                />
              </Box>

              <Box justifyContent="center" alignItems="center" height={60}>
                {error && (
                  <Box fontSize={11} color="#ff0000">
                    {error}
                  </Box>
                )}
              </Box>
              <Box flex={1} justifyContent="center" mt={3}>
                <Button type="submit">Valider</Button>
              </Box>
              <Box justifyContent="center" alignItems="center" height={60}>
                <Link onClick={() => navigate("/login")}>
                  <Text style={{ textAlign: "center", fontSize: 12 }}>
                    Retour à la page de connexion
                  </Text>
                </Link>
              </Box>
            </Form>
          </Formik>
        </Card>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>
          © 2024 Cofidoc Groupe
        </Text>
      </Box>
    </Box>
  );
}
