import { fetchAPITaxi } from "@/utils/fetchAPITaxi";

export type ProductName =
  | "Forfait avec Engagement"
  | "Gestion+ avec Engagement"
  | "Saisie PREMIER"
  | "Gestion+"
  | "eCofiCoffre";

export async function fetchTaxiData() {
  try {
    const res = await fetchAPITaxi({
      url: `/account/data`,
      options: {
        method: "POST",
      },
    });
    return res.map((c) => ({ ...c, date: new Date(c.date) }));
  } catch (error) {
    throw new Error();
  }
}
