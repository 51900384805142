import { useAppContext } from "@/provider/common/AppProvider";
import { Box, Card, Text, Modal, Href } from "@/ui";
import { useState } from "react";
import { Skeleton, useTheme } from "@mui/material";
import { CofidocContract } from "@/api/billing/fetchCofidocContracts";
import { format } from "date-fns";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import { ModalMobile } from "@/ui/ModalMobile";

const ContractCard = ({
  contract,
  handleOpenModal,
  theme,
  title,
  description1,
  description2,
  showIcon,
}: any) => {
  if (contract === null) {
    return (
      <Box
        flex={0.35}
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Card
          bgcolor={contract ? theme.colors.white : theme.palette.grey[100]}
          flexDirection="column"
          position="relative"
          height="100%"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box width="100%" justifyContent="center" alignItems="center" p={2}>
            <Skeleton variant="rounded" width={210} height={20} />
          </Box>
          <Box width="100%" p={1}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={20}
            />
          </Box>
          <Box width="100%" p={1}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={20}
            />
          </Box>
          <Box width="100%" p={1}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={20}
            />
          </Box>
        </Card>
      </Box>
    );
  }
  return (
    <Card
      bgcolor={contract ? theme.colors.white : theme.palette.grey[100]}
      display="flex"
      alignItems="center"
      flexDirection="column"
      position="relative"
      width="100%"
    >
      <Box alignItems="center" justifyContent="center" p={2} pb={0}>
        <Text fontSize={20} pl={2} fontWeight="800">
          {(contract?.productName.includes("Liberté") ||
            contract?.productName.includes("Fidélité")) &&
            "Forfait "}
          {title}
        </Text>
      </Box>
      {showIcon && (
        <Box
          bgcolor={theme.palette.primary.main}
          width="50px"
          height="50px"
          borderRadius="50px"
          position="absolute"
          top="-1px"
          right="-1px"
          justifyContent="center"
          alignItems="center"
        >
          <div
            className="white-smile"
            style={{ width: "28px", height: "28px" }}
          />
        </Box>
      )}
      <Box flexDirection="column" width="80%" pb={2}>
        {contract?.productName.includes("Liberté") && (
          <>
            <Text
              color={theme.palette.grey[500]}
              fontSize={14}
              fontWeight="600"
            >
              SANS ENGAGEMENT
            </Text>
            <Text
              color={theme.palette.grey[500]}
              fontSize={14}
              fontWeight="600"
            >
              COFFRE-FORT NUMERIQUE
            </Text>
            <Box flexDirection="row">
              <Text fontWeight="600" mr={2}>
                Mon taux:
              </Text>

              <Text>{contract?.percentage}% du chiffre d'affaire traité</Text>
            </Box>
          </>
        )}
        {contract?.productName.includes("Fidélité") && (
          <>
            <Text
              color={theme.palette.grey[500]}
              fontSize={14}
              fontWeight="600"
            >
              ABONNEMENT {contract.commitmentDuration} MOIS
            </Text>
            <Text
              color={theme.palette.grey[500]}
              fontSize={14}
              fontWeight="600"
            >
              COFFRE-FORT NUMERIQUE
            </Text>
            <Box flexDirection="row">
              <Text fontWeight="600" mr={2}>
                Mensualité:
              </Text>
              <Text>{contract?.productBase}€ / mois</Text>{" "}
            </Box>
          </>
        )}
        {!!contract?.subscriptionDate && (
          <Box flexDirection="row">
            <Text fontWeight="600" mr={2}>
              Date de souscription:
            </Text>
            <Text>
              {format(new Date(contract?.subscriptionDate), "dd MMMM yyyy")}
            </Text>
          </Box>
        )}
        {!contract && title?.includes("Option") && (
          <Box flexDirection="column">
            <Text
              color={theme.palette.grey[500]}
              fontSize={12}
              fontWeight="700"
              textAlign="center"
            >
              Optez pour la tranquillité : profitez du suivi bancaire et de la
              relance d’impayés ! Contactez votre gestionnaire pour en
              bénéficier.
            </Text>
          </Box>
        )}
      </Box>
    </Card>
  );
};

const Contracts = () => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const { contract, optionGestion } = useAppContext();

  const handleOpenModal = (contract) => {
    setSelectedContract(contract);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedContract(null);
  };

  return (
    <>
      <RenderModal
        contract={selectedContract}
        open={openModal}
        onClose={handleCloseModal}
      />
      <ContractCard
        contract={contract}
        handleOpenModal={handleOpenModal}
        theme={theme}
        icon={LocalOfferIcon}
        title={contract?.productName}
        description1={contract?.description}
        showIcon
      />
      <Box p={2} />
      <ContractCard
        contract={optionGestion}
        handleOpenModal={handleOpenModal}
        theme={theme}
        icon={AddModeratorIcon}
        title="Option Tranquilité"
        description1="POINTAGE BANCAIRE"
        description2="SUIVI & RELANCE DES IMPAYÉS"
      />
      <Box p={2} />
      {/* <ContractCard
        contract={optionECoficoffre}
        handleOpenModal={handleOpenModal}
        theme={theme}
        icon={InventoryIcon}
        type="ecoficoffre"
        title="e-CofiCoffre"
        description1="E-COFFRE SECURISE"
        description2="POUR VOS DONNEES PROFESSIONELLES"
      /> */}
    </>
  );
};

const RenderModal = ({
  open,
  contract,
  onClose,
}: {
  open: boolean;
  onClose: any;
  contract?: CofidocContract;
}) => {
  const detail = modalDetails[contract?.productName];
  const theme = useTheme();

  return (
    <ModalMobile open={open} onClose={onClose}>
      <Box
        width="100%"
        maxWidth="100%"
        flexDirection="row"
        height="100%"
        alignItems="center"
        position="relative"
      >
        <Box width="100%" flexDirection="column" p={3} zIndex={1}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              style={{
                fontSize: 28,
                fontWeight: 500,
                color: theme.colors.primary,
                textAlign: "left",
              }}
            >
              {detail?.title}
            </Text>
            <Box p={2} />
            <Box flexDirection="column" pt={2} width="100%">
              {!!contract?.subscriptionDate && (
                <Box flexDirection="row">
                  <Text fontWeight="600" mr={2}>
                    Date de souscription:
                  </Text>
                  <Text>
                    {format(
                      new Date(contract?.subscriptionDate),
                      "dd MMMM yyyy"
                    )}
                  </Text>
                </Box>
              )}
              {contract?.productName.includes("Fidélité") && (
                <Box flexDirection="row">
                  <Text fontWeight="600" mr={2}>
                    Mensualité:
                  </Text>

                  <Text>{contract?.productBase}€ / mois</Text>
                </Box>
              )}
              {!contract?.productName.includes("Fidélité") && (
                <Box flexDirection="row">
                  <Text fontWeight="600" mr={2}>
                    Mon taux:
                  </Text>
                  {contract?.percentage}% du chiffre d'affaire traité
                </Box>
              )}
            </Box>
            <Box p={2} />
            {detail?.texts.map((text, index) => (
              <Text key={index} textAlign="justify" mt={1}>
                {text}
              </Text>
            ))}

            {detail?.buttonText && (
              <Box
                m={3}
                width="100%"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <Href target="blank" href={detail.buttonLink}>
                  {detail.buttonText}
                </Href>
              </Box>
            )}
            <Text textAlign="center" my={3}>
              <a href="tel:0806080808" style={{ color: theme.colors.primary }}>
                08 06 08 08 08
              </a>
            </Text>
          </Box>
        </Box>
        <Box
          width="100%"
          height="100%"
          position="absolute"
          style={{ opacity: 0.3, zIndex: 0 }}
        >
          <div className="image-cofidoc-metier" />
        </Box>
        <Box
          onClick={onClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: 15,
            right: 30,
            zIndex: 2,
          }}
        >
          <Text
            color={theme.palette.primary.main}
            textAlign="center"
            fontWeight="700"
          >
            FERMER
          </Text>
        </Box>
      </Box>
    </ModalMobile>
  );
};

const modalDetails = {
  eCofiCoffre: {
    title: "eCofiCoffre",
    texts: [
      "Vos données sécurisée et accéssible depuis votre espace web Cofidoc.",
    ],
    buttonText: "Contacter ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
  },
  "Gestion+": {
    title: "Gestion+",
    texts: [
      "Vous n'avez pas le temps de faire vos rapprochements bancaires et de gérer vos impayés ? Nous nous en occupons!",
      "Complétez votre offre en souscrivant l'option Gestion + afin de confier à votre agence Cofidoc vos rapprochements bancaires et la relance automatique de vos impayés.",
      "Nous effectuons le pointage à partir de vos relevés de compte bancaire et de la comptabilité de notre logiciel de facturation.",
      "Vous devrez fournir à votre agence vos relevés régulièrement afin de pouvoir suivre vos paiements et effectuer la relance des impayés si nécessaire.",
    ],
    buttonText: "Contacter ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
  },
  "Saisie PREMIER": {
    title: "Forfait avec Engagement",
    texts: [
      "Un pourcentage du CA traité sans engagement, ou Un forfait fixe sur 12, 24 ou 36 mois pour faire des économies.",
      "Et notre Application Mobile en cadeau pour tout gérer depuis son téléphone En quelques clics, toute votre facturation est envoyée à votre gestionnaire qui se charge de tout contrôler avant de facturer",
    ],
    schedule: true,
    buttonText: "Contacter ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
  },
};

export default Contracts;
