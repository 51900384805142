import { useSystemContext } from "@/provider/common/SystemProvider";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getAccessToken } from "@/utils/localStorage";
import { PageLoader } from "@/components/common/PageLoader";

export function Root() {
  const { loading } = useSystemContext();
  const location = useLocation();
  const navigate = useNavigate();

  const authorizedRoot = ["/login", "/forgot-password", "/create-password"];
  const token = getAccessToken();

  async function start() {
    try {
      if (token && token != "undefined" && location?.pathname === "/") {
        navigate(`/home`, { replace: true });
      } else if (authorizedRoot.includes(location.pathname)) {
        return;
      }
    } catch (e) {
      console.log("=> error:", e);
    }
  }

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <PageLoader />}
      <Outlet />
    </>
  );
}
