import { Box, Href, Text } from "@/ui";
import { useTheme } from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import ShieldIcon from "@mui/icons-material/Shield";

export default function Coficoffre() {
  const theme = useTheme();
  return (
    <Box flexDirection="column" width="100%" height="100%" className="overBg" style={{ overflowY: "scroll" }} p={4} py={5} pl={0}>
      <Box
        width="100%"
        height="100%"
        flexDirection="column"
        bgcolor="#fafafaec"
        boxShadow="7px 7px 12px 0px #c3c3c3"
        borderRadius={5}
        alignItems="center"
      >
        <Box width="100%" flexDirection="column" p={5} height="15%">
          <Text fontSize={35} fontWeight="800">
            Mes outils,
          </Text>
          <Text fontSize={18} fontWeight="300">
            Mes données sécurisées dans mon E-Coficoffre
          </Text>
        </Box>

        <Box
          px={5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="75%"
          style={{ overflowY: "scroll" }}
          maxWidth="1200px"
          className="hiddenScrollBarAndChildren"
        >
          <Box width="90%" flexDirection="row" p={1} alignItems="center">
            <Text fontSize={20} fontWeight="300" width="50%" textAlign="justify">
              Vos informations sont stockées de manière sécurisée sur des serveurs <span style={{ color: theme.palette.primary.main }}>HDS</span>{" "}
              (Hébergement de Données de Santé) certifiés par l'
              <span style={{ color: theme.palette.primary.main }}>Agence du Numérique en santé</span>. Consultez, téléchargez ou imprimez vos données
              quand vous le souhaitez. Grâce à notre système de transfert automatique, profitez d’une tranquillité d’esprit totale.
            </Text>
            <Box position="relative" width="50%" justifyContent="center" alignItems="center" flexDirection="column">
              <CloudIcon
                style={{
                  color: theme.palette.primary.light,
                  fontSize: "170px",
                  position: "absolute",
                  zIndex: 1,
                }}
              />
              <ShieldIcon
                style={{
                  color: "#fff",
                  fontSize: "90px",
                  position: "absolute",
                  zIndex: 2,
                }}
              />
              <div
                className="image-smile"
                style={{
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 3,
                }}
              />
              <Box height="200px" />
              <Box width="90%" alignItems="center" justifyContent="center">
                <Href target="blank" href="https://cofidoc.netexplorer.pro/oauth2/login">
                  Acceder à mon coffre sécurisé
                </Href>
              </Box>
            </Box>
          </Box>
          <Box p={4} />
          <Box width="90%" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box width="50%" alignItems="center">
              <Box className="image-netexporer1" style={{ boxShadow: "0px 0px 12px -4px #aaaaaa" }} />
            </Box>
            <Text fontSize={20} fontWeight="300" width="50%">
              Pour accéder à votre <span style={{ color: theme.palette.primary.main }}>e-Coficoffre</span>, cliquez sur{" "}
              <span style={{ color: theme.palette.primary.main }}>Se connecter avec Cofidoc</span>
            </Text>
          </Box>
          <Box p={4} />
          <Box width="90%" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box width="50%" alignItems="center">
              <Box className="image-netexporer2" style={{ boxShadow: "0px 0px 12px -4px #aaaaaa" }} />
            </Box>
            <Text fontSize={20} fontWeight="300" width="50%">
              Dans les champs <span style={{ color: theme.palette.primary.main }}>email</span> et{" "}
              <span style={{ color: theme.palette.primary.main }}>password</span>, renseignez les mêmes identifiants que votre espace web et cliquez
              sur <span style={{ color: theme.palette.primary.main }}>Sign In</span>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
