import { Box, Card, Text } from "@/ui";
import AppleIcon from "@mui/icons-material/Apple";
import WindowIcon from "@mui/icons-material/Window";

import { useTheme } from "@mui/material";
import { useSystemContext } from "@/provider/common/SystemProvider";

export default function Tools() {
  const theme = useTheme();
  const { getUser } = useSystemContext();
  const user = getUser();

  return (
    <Box flexDirection="column" width="100%" height="100%" className="overBg" style={{ overflowY: "scroll" }} p={4} py={5} pl={0}>
      <Box
        width="100%"
        height="100%"
        flexDirection="column"
        bgcolor="#fafafaec"
        boxShadow="7px 7px 12px 0px #c3c3c3"
        borderRadius={5}
        alignItems="center"
      >
        <Box width="100%" flexDirection="column" p={5} pb={0} height="15%">
          <Text fontSize={35} fontWeight="800">
            Mes outils,
          </Text>
          <Text fontSize={18} fontWeight="300">
            Comment fonctionne la connexion à distance
          </Text>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          mt={10}
          alignItems="center"
          height="85%"
          style={{ overflowY: "scroll" }}
          maxWidth="900px"
          className="hiddenScrollBarAndChildren"
        >
          <Box mb={5} width="100%" flexDirection="column" p={5} height="15%">
            <Text fontSize={18} fontWeight="300" textAlign="justify">
              Donnez accès à votre gestionnaire aux données de votre carte CPS sans déplacement. Avec une connexion protégée et un accès strictement
              contrôlé, installez facilement notre solution et bénéficiez d'un suivi à distance en toute confidentialité
            </Text>
          </Box>
          <Box mb={5}>
            <Text fontSize={25} fontWeight="500">
              Comment ça marche ?
            </Text>
          </Box>
          <Card
            boxShadow={`9px 9px 12px -7px ${theme.palette.primary.light}`}
            display="flex"
            flexDirection="row"
            border={`solid 2px ${theme.palette.primary.light}`}
            p={0}
            width="90%"
          >
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              width="15%"
              bgcolor={theme.palette.primary.light}
              borderRadius="12px 0px 0px 12px"
            >
              <Text fontFamily="Caveat" fontSize="60px" color={theme.palette.primary.main}>
                1
              </Text>
            </Box>
            <Box flexDirection="column" width="85%" p={4}>
              <Text
                fontFamily="Caveat"
                style={{
                  fontSize: 30,
                  marginTop: 5,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                }}
              >
                Mon lecteur :
              </Text>
              <Text style={{ fontSize: 20 }} py={3}>
                Un lecteur en Mode PC/SC Au minimum, <span style={{ color: theme.palette.primary.main }}>Windows7</span> ou{" "}
                <span style={{ color: theme.palette.primary.main }}>Mac OS 12 Monteray</span>.
              </Text>
            </Box>
          </Card>
          <Box minHeight="70px" border={`dashed 2px ${theme.palette.primary.light}`} />
          <Card
            boxShadow={`9px 9px 12px -7px ${theme.palette.primary.light}`}
            display="flex"
            flexDirection="row"
            border={`solid 2px ${theme.palette.primary.light}`}
            p={0}
            width="90%"
          >
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              width="15%"
              bgcolor={theme.palette.primary.light}
              borderRadius="12px 0px 0px 12px"
            >
              <Text fontFamily="Caveat" fontSize="60px" color={theme.palette.primary.main}>
                2
              </Text>
            </Box>
            <Box flexDirection="column" width="85%" p={4}>
              <Text
                fontFamily="Caveat"
                style={{
                  fontSize: 30,
                  marginTop: 5,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                }}
              >
                Comment installer ma connexion à distance :
              </Text>
              <Box alignItems="center" py={3}>
                <Box flexDirection="column" justifyContent="center" alignItems="center" width="15%">
                  <WindowIcon style={{ color: theme.colors.blue.main }} />
                  <Text>Windows</Text>
                </Box>
                <Box flexDirection="column" width="85%" pl={2}>
                  <Text style={{ fontSize: 18 }}>
                    - Téléchargez le lien suivant{" "}
                    <a
                      href="https://infi-public.s3.eu-west-3.amazonaws.com/previous-compilation-data.bin"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: theme.palette.primary.dark,
                      }}
                    >
                      Cofidoc Connect Windows
                    </a>{" "}
                    et copier le fichier sur le bureau.
                  </Text>
                </Box>
              </Box>
              <Box alignItems="center" py={3}>
                <Box flexDirection="column" justifyContent="center" alignItems="center" width="15%">
                  <AppleIcon style={{ color: theme.colors.blue.main }} />
                  <Text>Mac OS</Text>
                </Box>
                <Box flexDirection="column" width="85%" pl={2}>
                  <Text style={{ fontSize: 18 }}>
                    - Téléchargez le lien suivant{" "}
                    <a
                      href="https://infi-public.s3.eu-west-3.amazonaws.com/previous-compilation-data.bin"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: theme.palette.primary.dark,
                      }}
                    >
                      Cofidoc Connect Mac
                    </a>{" "}
                    et déplacer dans “Application”.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Card>
          <Box minHeight="70px" border={`dashed 2px ${theme.palette.primary.light}`} />
          <Card
            boxShadow={`9px 9px 12px -7px ${theme.palette.primary.light}`}
            display="flex"
            flexDirection="row"
            border={`solid 2px ${theme.palette.primary.light}`}
            p={0}
            width="90%"
          >
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              width="15%"
              bgcolor={theme.palette.primary.light}
              borderRadius="12px 0px 0px 12px"
            >
              <Text fontFamily="Caveat" fontSize="60px" color={theme.palette.primary.main}>
                3
              </Text>
            </Box>
            <Box flexDirection="column" width="85%" p={4}>
              <Text
                fontFamily="Caveat"
                style={{
                  fontSize: 30,
                  marginTop: 5,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                }}
              >
                Que dois-je faire ?
              </Text>
              <Box flexDirection="column" py={3}>
                <Text style={{ fontSize: 18 }}>
                  - Cliquez sur votre nouvelle icone <span style={{ color: theme.palette.primary.main }}>COFIDOC Connect</span>.
                </Text>
                <Text style={{ fontSize: 18 }}>
                  - Dans le champ <span style={{ color: theme.palette.primary.main }}>Token</span> saisissez:{" "}
                  <span style={{ color: theme.palette.primary.main }}>{user?.token}</span>
                </Text>
                <Text style={{ fontSize: 18 }}>
                  - Dans le Champ <span style={{ color: theme.palette.primary.main }}>Nom</span> mettre votre nom et prénom.
                </Text>
              </Box>
            </Box>
          </Card>
          <Box minHeight="70px" border={`dashed 2px ${theme.palette.primary.light}`} />
          <Card
            boxShadow={`9px 9px 12px -7px ${theme.palette.primary.light}`}
            display="flex"
            flexDirection="row"
            border={`solid 2px ${theme.palette.primary.light}`}
            p={0}
            width="90%"
          >
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              width="15%"
              bgcolor={theme.palette.primary.light}
              borderRadius="12px 0px 0px 12px"
            >
              <Text fontFamily="Caveat" fontSize="60px" color={theme.palette.primary.main}>
                4
              </Text>
            </Box>
            <Box flexDirection="column" width="85%" p={4}>
              <Text
                fontFamily="Caveat"
                style={{
                  fontSize: 30,
                  marginTop: 5,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                }}
              >
                Quand et comment l'utiliser ?
              </Text>
              <Box flexDirection="column" py={3}>
                <Text style={{ fontSize: 18 }}>
                  - Branchez votre <span style={{ color: theme.palette.primary.main }}>lecteur</span> avec la CPS insérée.
                </Text>
                <Text style={{ fontSize: 18 }}>
                  - Lancez <span style={{ color: theme.palette.primary.main }}>COFIDOC Connect</span> et connectez vous
                </Text>
                <Text style={{ fontSize: 18 }}>
                  - N'oubliez pas de fermer <span style={{ color: theme.palette.primary.main }}>COFIDOC Connect</span> quand votre gestionnaire a fini
                  son travail
                </Text>
              </Box>
            </Box>
          </Card>
          <Box height={100} minHeight={100} />
        </Box>
      </Box>
    </Box>
  );
}
