import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getAccessToken } from "@/utils/localStorage";
import { useSystemContext } from "@/provider/common/SystemProvider";

export function PrivateRoute({ Component }) {
  const token = getAccessToken();
  const location = useLocation();
  const { getUser } = useSystemContext();
  const user = getUser();

  if (!user || !token || token === "undefined") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Component />;
}
