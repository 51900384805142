import React, { ReactNode } from "react";
import AccordionMUI from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

interface AccordionProps {
  title?: string;
  children: React.ReactNode;
  width?: any;
  boxShadow?: string;
  HeaderComponent?: any;
}

const Accordion: React.FC<AccordionProps> = ({ HeaderComponent, title, children, width, boxShadow = "9px 9px 12px -7px #aaaaaa" }) => {
  return (
    <AccordionMUI style={{ borderRadius: "10px", boxShadow, width, padding: "10px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#d53ea0" }} />}
        aria-controls={`panel-${title}-content`}
        id={`panel-${title}-header`}
      >
        {HeaderComponent ? <HeaderComponent /> : <Typography>{title}</Typography>}
      </AccordionSummary>
      <AccordionDetails
        style={{
          width: "100%",
          minWidth:"100%",
          overflowX: "auto",
          padding: 0,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </AccordionDetails>
    </AccordionMUI>
  );
};

export { Accordion };
