import { Box } from "./Box";

export function ModalMobile({ children, open, onClose }: any) {
  return (
    <>
      {open && (
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 10,
            top: 0,
            left: 0,
          }}
        >
          <Box position="absolute" width="100%" height="100%" onClick={onClose ? onClose : null} bgcolor="#b2b2b258" zIndex={11} />
          <Box
            style={{
              minWidth: "95%",
              width: "95%",
              minHeight: "250px",
              flexDirection: "column",
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              boxShadow: "0px 0px 17px 0px #ababab",
              overflow: "hidden",
              position: "absolute",
              zIndex: 12,
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </>
  );
}
