import { ApplicationType } from "@/types/common";
import { getLocalStorageApplication, getUserStorage, setLocalStorageApplication, setUserStorage } from "@/utils/localStorage";
import React, { createContext, useContext, useEffect, useState } from "react";

type ApplicationContextType = {
  // to delete
  application: ApplicationType;
  setUser: any;
  getUser: any;
  dbName: any;
  isMobile: boolean;
  setLoading: any;
  loading: boolean;
};

const ApplicationContext = createContext<ApplicationContextType | undefined>(undefined);

export const SystemProvider = ({ children }: any) => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  // Choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const app = getLocalStorageApplication();
  const [application] = useState<ApplicationType>(app);

  function setUserAll(user) {
    console.log("=>  setUserAll:", user);
    if (user) setUserStorage(user);
    setUser(user ? JSON.parse(user) : "");
  }

  function getUser() {
    if (user) {
      return user;
    } else {
      let user = getUserStorage();
      setUser(user);
      return user;
    }
  }

  return (
    <ApplicationContext.Provider
      value={{
        setUser: setUserAll,
        getUser,
        // to delete
        dbName: "",
        // to delete
        application,
        setLoading,
        loading,
        isMobile,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export function useSystemContext() {
  const context = useContext(ApplicationContext);
  if (!context) throw new Error("useSystemContext must be used within a SystemProvider");
  return context;
}
