import { Accordion, Box, Button, Text } from "@/ui";
import imagephone from "../../../../images/mockup-mobi.png";
import { useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Office } from "@/types/nurse";
import { useMongoContext } from "@/provider/common/MongoProvider";
import { fetchOffices } from "@/api/nurse/fetchOffices";
import { useSystemContext } from "@/provider/common/SystemProvider";
import InfoIcon from "@mui/icons-material/Info";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HistoryIcon from "@mui/icons-material/History";
import { format } from "date-fns";
import { ReactComponent as SVGArrow } from "../../../../images/arrow.svg";

export default function Tools() {
  const [data, setData] = useState<{ office: Office; patientCount: number; seancesCount: number }[]>([]);
  console.log("=>  data:", data);
  const { setLoading, loading } = useSystemContext();
  const { client, user } = useMongoContext();
  const { application } = useSystemContext();

  async function getData() {
    try {
      setLoading(true);
      const data = await fetchOffices({ application });
      setData(data);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    //A virer, sert pour éviter les call api a chaque chargement
    // if (user && client && !data) {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client]);

  return (
    <Box flexDirection="column" width="100%" height="100%" className="overBg" style={{ overflowY: "scroll" }} p={4} py={5} pl={0}>
      <Box
        width="100%"
        height="100%"
        flexDirection="column"
        bgcolor="#fafafaec"
        boxShadow="7px 7px 12px 0px #c3c3c3"
        borderRadius={5}
        alignItems="center"
        position="relative"
      >
        <Box width="100%" flexDirection="column" p={5} height="15%">
          <Text fontSize={35} fontWeight="800">
            Mes outils,
          </Text>
          <Text fontSize={18} fontWeight="300">
            Mon application gratuite: Cofidoc Mobility
          </Text>
        </Box>
        {!data?.length && !loading && <RenderEmptyOffices />}
        {!!data?.length && !loading && <RenderOffices data={data} />}
      </Box>
    </Box>
  );
}

function RenderOffices({ data }: { data: { office: Office; patientCount: number; seancesCount: number }[] }) {
  const theme = useTheme();
  return (
    <Box px={5} display="flex" flexDirection="column" height="85%" style={{ overflowY: "scroll" }} maxWidth="1200px" width="100%">
      <Box p={2} flexDirection="row" alignItems="center" borderRadius={2} bgcolor={theme.colors.blue.pale}>
        <Box p={4}>
          <InfoIcon style={{ fontSize: "40px", color: "#fff" }} />
        </Box>
        <Text color={theme.colors.blue.dark} fontSize={20}>
          Retrouvez prochainement l'intégralité de votre activité dans l'onglet "Application"
        </Text>
      </Box>
      <Box p={3} />
      <Box flexDirection="column" width="100%" justifyContent="flex-start" alignItems="flex-start">
        <Box p={2}>
          <Text fontSize={20}>
            {data?.length > 1 ? "Visualisez les informations de vos cabinets" : "Visualisez les informations de votre cabinet"}{" "}
          </Text>
        </Box>
        {data?.map(({ office, patientCount, seancesCount }) => {
          return (
            <Box p={2} width="100%">
              <Accordion
                width="100%"
                key={office._id}
                HeaderComponent={() => (
                  <>
                    <HomeWorkIcon
                      style={{
                        color: theme.palette.primary.light,
                        fontSize: "35px",
                      }}
                    />
                    <Text pl={4} fontSize="30px" fontFamily="Caveat" color={theme.palette.primary.main}>
                      {office?.name}
                    </Text>
                  </>
                )}
              >
                <Box flexDirection="column" width="100%" p={3}>
                  <Box alignItems="center">
                    <AssignmentIndIcon
                      style={{
                        color: theme.palette.primary.light,
                        fontSize: "20px",
                      }}
                    />
                    <Text fontSize="16px" pl={4}>
                      Nombre de patient: {patientCount}
                    </Text>
                  </Box>
                  <Box alignItems="center">
                    <FactCheckIcon
                      style={{
                        color: theme.palette.primary.light,
                        fontSize: "20px",
                      }}
                    />
                    <Text fontSize="16px" pl={4}>
                      Nombre de séances: {seancesCount}
                    </Text>
                  </Box>

                  {office?.createdAt && (
                    <Box alignItems="center">
                      <HistoryIcon
                        style={{
                          color: theme.palette.primary.light,
                          fontSize: "20px",
                        }}
                      />
                      <Text fontSize="16px" pl={4}>
                        Cabinet créé le {format(new Date(office?.createdAt), "eeee d MMMM yyyy")}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Accordion>
            </Box>
          );
        })}
        <Box height="50px" />
      </Box>
    </Box>
  );
}

function RenderEmptyOffices() {
  return (
    <Box px={5} display="flex" flexDirection="row" alignItems="center" height="85%" style={{ overflowY: "scroll" }} width="100%">
      <Box flexDirection="column" width="50%" height="100%" alignItems="center">
        <Box height="200px" justifyContent="flex-start" alignItems="flex-start" width="100%" marginLeft="200px">
          <Box style={{ transform: "rotate(100deg)" }}>
            <SVGArrow style={{ minWidth: "50px", minHeight: "50px", maxWidth: "50px", maxHeight: "50px", transform: "rotateY(190deg)" }} />
          </Box>
          <Text
            pl={4}
            pt={4}
            width="300px"
            fontFamily="Caveat"
            fontSize={25}
            fontWeight="900"
            textAlign="center"
            style={{ transform: "rotate(-7deg)" }}
          >
            Un moyen simple et rapide <br /> de gérer vos journées <br /> et données de facturation !
          </Text>
        </Box>
        <Box justifyContent="center" width="100%" height="50%" alignItems="center">
          <Text fontSize="20px" lineHeight="38px" textAlign="justify" fontWeight="300" width="450px">
            Avec notre application intuitive, envoyez vos documents à votre gestionnaire en quelques secondes. Elle s'occupe du reste ! Rapide,
            simple, et conçue pour votre métier, la facturation n’a jamais été aussi facile.
          </Text>
        </Box>
      </Box>
      <Box justifyContent="center" alignItems="flex-start" width="50%" overflow="hidden" height="90%" style={{ overflow: "visible" }}>
        <Box height="100%" justifyContent="center" alignItems="center">
          <CurvedLine />
        </Box>

        <Box width="40%" height="100%" flexDirection="column" justifyContent="center" alignItems="flex-start">
          <img
            src={imagephone}
            alt="phone"
            style={{
              objectFit: "cover",
              height: "70%",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

const CurvedLine = () => {
  const pathRef = useRef(null);
  const [points, setPoints] = useState([]);

  const texts = [
    "Envoi des actes à facturer à votre gestionnaire Cofidoc",
    "Partage de vos données entre membres du cabinet ",
    "Cotations simplifiées",
    "Vos transmissions et le suivi de vos patients",
    "Gestion des ordonnances des BSI et des patients",
    "Création de vos tournées",
  ];

  const subTexts = ["", "(titulaires et remplaçants)", "", "", "", ""];

  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength();
      const newPoints = [];
      // Diviser la longueur totale du chemin en 6 parties pour placer les points
      for (let i = 0; i <= 5; i++) {
        const point = pathRef.current.getPointAtLength((pathLength / 5) * i);
        newPoints.push({ x: point.x, y: point.y });
      }
      setPoints(newPoints);
    }
  }, []);

  return (
    <svg viewBox="0 0 1 600" width="200px" height="80%" xmlns="http://www.w3.org/2000/svg" style={{ overflow: "visible" }}>
      <path ref={pathRef} d="M 100 600 C -50 400, -50 200, 100 0" stroke="#ffcae7" strokeWidth="3" fill="none" />
      {points.map((point, index) => (
        <>
          <circle key={index} cx={point.x} cy={point.y} r="6" fill="#ffcae7" />
          <foreignObject x={point.x - 240} y={point.y + -10} fontSize="12" fill="#333" style={{ width: "220px", height: "55px" }}>
            <div>
              <div style={{ fontSize: "16px", color: "#333", textAlign: "right", width: "100%", lineHeight: "1.2em" }}>{texts[index]}</div>
              <div style={{ fontSize: "12px", color: "#333", textAlign: "right", width: "100%", lineHeight: "1.2em" }}>{subTexts[index]}</div>
            </div>
          </foreignObject>
        </>
      ))}
    </svg>
  );
};
