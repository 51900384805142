import { Box, Card, Text } from "@/ui";
import { useTheme } from "@mui/material";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { Divider } from "@mui/material";

export default function Profile() {
  return (
    <Box flexDirection="column" width="100%" height="100%" className="overBg" style={{ overflowY: "scroll" }} p={4} py={5} pl={0}>
      <Box
        width="100%"
        height="100%"
        flexDirection="column"
        alignItems="center"
        bgcolor="#fafafaec"
        boxShadow="7px 7px 12px 0px #c3c3c3"
        borderRadius={5}
      >
        <Box width="100%" flexDirection="column" p={5} height="15%">
          <Text fontSize={35} fontWeight="800">
            Mes informations
          </Text>
        </Box>
        <Box
          px={5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="65%"
          style={{ overflowY: "scroll" }}
          maxWidth="1200px"
          className="hiddenScrollBarAndChildren"
          width="100%"
        >
          <Card display="flex" flexDirection="row" p={0} width="100%" maxWidth="1200px">
            <RenderProfil />
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

function RenderProfil() {
  const { getUser } = useSystemContext();
  const theme = useTheme();
  let user = getUser();

  return (
    <Box p={4} flexDirection="column" width="100%">
      <Box p={2} flexDirection="column">
        <Text fontSize={16} color={theme.palette.primary.main}>
          Nom / Prénom
        </Text>
        <Text fontSize={18}>
          {user?.prenom} {user?.nom}
        </Text>
      </Box>
      <Divider />
      <Box p={2} flexDirection="column">
        <Text fontSize={16} color={theme.palette.primary.main}>
          Email
        </Text>
        <Text fontSize={18}>{user?.email}</Text>
      </Box>
      <Divider />
      <Box p={2} flexDirection="column">
        <Text fontSize={16} color={theme.palette.primary.main}>
          Identifiant
        </Text>
        <Text fontSize={18}>{user?.identifiant}</Text>
      </Box>
      {user?.token && (
        <>
          <Divider />
          <Box p={2} flexDirection="column">
            <Text fontSize={16} color={theme.palette.primary.main}>
              Token
            </Text>
            <Text fontSize={18}>{user?.token}</Text>
          </Box>
        </>
      )}
    </Box>
  );
}
