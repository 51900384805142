import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Box, Text, Card, Accordion } from "@/ui";
import { groupBy } from "lodash";

export function ReportViewCpnWeb({ reports, formatDate }: any) {
  return (
    <Box
      width="100%"
      alignItems="center"
      flexDirection="column"
      height="100%"
      p={5}
      style={{ overflowY: "scroll", overflowX: "hidden" }}
    >
      {reports.length > 0 ? (
        <>
          {/* Accordéon pour les détails des remboursements */}
          {reports.map((report, index) => {
            const mapped = report.factures?.map((f) => {
              return { ...f, title: f?.libelleCaisse || f?.libelleMutuelle };
            });
            const grouped = groupBy(mapped, "title");
            return (
              <Box
                width="100%"
                py={2}
                justifyContent="center"
                alignItems="center"
              >
                <Accordion
                  width="100%"
                  key={report.date.getTime() || index}
                  title={`Ma facturation du ${formatDate(report.date)}`}
                >
                  {!!report.commentaire && (
                    <Box flexDirection="column" width="90%" pb={3}>
                      <Box height="1px" width={"100%"} bgcolor="#d5d5d5" />
                      <Box flexDirection="column" py={2} width="100%">
                        <Text fontSize={13}>Commentaire gestionnaire:</Text>
                        <Text fontSize={17}>{report.commentaire}</Text>
                      </Box>
                      <Box height="1px" width={"100%"} bgcolor="#d5d5d5" />
                    </Box>
                  )}
                  {Object?.keys(grouped).map((key) => {
                    let sum = 0;
                    const prices = grouped[key].map((f) =>
                      parseFloat(f.montant)
                    );
                    for (let i = 0; i < prices.length; i++) {
                      sum += prices[i];
                    }
                    return (
                      <Box
                        width="100%"
                        p={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Accordion
                          width="90%"
                          key={report.date.getTime() || index}
                          title={`${key} - ${sum.toFixed(2)}€`}
                          boxShadow="rgb(170, 170, 170) 5px 5px 12px -2px"
                        >
                          <Table
                            size="small"
                            style={{ border: "1px solid #e0e0e0" }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Données</TableCell>
                                <TableCell>Facture</TableCell>
                                <TableCell>Patient</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Montant</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {grouped[key].map((facture, detailIndex) => (
                                <>
                                  <TableRow
                                    key={`${facture?.numero}`}
                                    style={{
                                      backgroundColor:
                                        detailIndex % 2 === 0
                                          ? "#fff"
                                          : "#f3e7e765",
                                    }}
                                  >
                                    <TableCell>
                                      <Text fontWeight="700" fontSize={11}>
                                        {facture?.libelle}
                                      </Text>
                                    </TableCell>
                                    <TableCell>{facture.numero}</TableCell>
                                    <TableCell>{facture.patient}</TableCell>
                                    <TableCell>
                                      {formatDate(new Date(facture.debut))} -{" "}
                                      {formatDate(new Date(facture.fin))}
                                    </TableCell>
                                    <TableCell>
                                      {parseFloat(facture.montant).toFixed(2)} €
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </Accordion>
                      </Box>
                    );
                  })}
                  <Box p={2} />
                </Accordion>
              </Box>
            );
          })}
        </>
      ) : (
        <Card flexDirection="column" display="flex" style={{ width: "100%" }}>
          <Box
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            p={2}
            m={2}
          >
            <Text fontSize={18} fontWeight={500}>
              Aucun compte rendu pour cette période
            </Text>
          </Box>
        </Card>
      )}
    </Box>
  );
}
