import { Formik, Form } from "formik";
import { Box, Text, Button, Link } from "../../../ui";
import { Card } from "../../../ui/Card";
import { TextField } from "../../../ui/field/TextField";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMongoContext } from "@/provider/common/MongoProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  fetchForgotPassword,
  fetchResetPassword,
} from "@/api/billing/fetchAuth";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { useTheme } from "@mui/material";

export default function ForgotPasswordView() {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { setLoading, isMobile } = useSystemContext();

  return (
    <Box
      height="100vh"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <div className="background-cloud height40" />
      <div className="background-over" />
      <div className="background-mountain-cofidoc" />
      <Box
        display="flex"
        p={2}
        alignItems="center"
        justifyContent="center"
        zIndex={2}
        position="absolute"
        top={20}
        left={20}
      >
        <div className="background-logo" />
      </Box>
      <Box
        width="100%"
        style={{
          position: "relative",
          zIndex: 4,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          width={isMobile ? "80%" : "550px"}
        >
          <Formik
            initialValues={
              step === 1 ? { email: "" } : { code: "", newPassword: "" }
            }
            onSubmit={async (values) => {
              try {
                setLoading(true);
                setError(null);
                if (step === 1) {
                  await fetchForgotPassword({
                    email: values.email,
                  });
                  setStep(2);
                } else {
                  await fetchResetPassword({
                    email: values.email,
                    code: values.code,
                    password: values.newPassword,
                  });
                  navigate("/login");
                }
              } catch (e) {
                console.log("error", e);
                setError(e?.message);
              } finally {
                setLoading(false);
              }
            }}
          >
            <Form
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display="flex" width="100%">
                <Link onClick={() => navigate("/login")}>
                  <ArrowBackIcon fontSize="small" />
                  <Box pr={1} />
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >
                    Retour au portail de connexion
                  </Text>
                </Link>
              </Box>

              <Box style={{ margin: 20 }}>
                <Text
                  fontSize={20}
                  fontWeight={500}
                  textAlign="center"
                  color={theme.colors.primary}
                >
                  J'ai oublié mon mot de passe
                </Text>
              </Box>
              <Box style={{ margin: 20, textAlign: "center" }}>
                <Text style={{ textAlign: "center" }}>
                  {step === 1
                    ? "Saississez votre adresse email"
                    : "Entrez le code reçu par email et le nouveau mot de passe"}
                </Text>
              </Box>
              <Box width="80%">
                {step === 1 ? (
                  <TextField label="Email" name="email" width="100%" />
                ) : (
                  <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                    <TextField label="Code" name="code" width="100%" />
                    <Box my={4}></Box>
                    <TextField
                      type="password"
                      label="Nouveau mot de passe"
                      name="newPassword"
                      width="100%"
                    />
                  </Box>
                )}
              </Box>
              <Box justifyContent="center" alignItems="center" height={60}>
                {error && (
                  <Box fontSize={11} color="#ff0000">
                    {error}
                  </Box>
                )}
              </Box>
              <Box flex={1} justifyContent="center" mt={3}>
                <Button type="submit">
                  {step === 1 ? "Envoyer" : "Valider"}
                </Button>
              </Box>
              <Box justifyContent="center" alignItems="center" height={60}>
                <Link onClick={() => navigate("/login")}>
                  <Text style={{ textAlign: "center", fontSize: 12 }}>
                    Annuler
                  </Text>
                </Link>
              </Box>
            </Form>
          </Formik>
        </Card>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>
          © 2024 Cofidoc Groupe
        </Text>
      </Box>
    </Box>
  );
}
