import { InvoiceItem } from "@/components/common/home/InvoiceItem";
import { Box, Text } from "@/ui";
import { useTheme } from "@mui/material";

export function BillViewMobile({ billing }: any) {
  const theme = useTheme();
  return (
    <>
      <Box
        width="100%"
        flexDirection="column"
        justifyContent="center"
        height="90px"
        borderBottom={`solid 1px ${theme.palette.primary.light}`}
      >
        <Text fontSize={28} fontWeight="800" paddingLeft="70px">
          <span style={{ color: theme.palette.primary.main }}>G</span>estion{" "}
          <span style={{ color: theme.palette.primary.main }}>C</span>
          ofidoc,
        </Text>
        <Text fontSize={16} fontWeight="300" paddingLeft="70px">
          Mes factures
        </Text>
      </Box>
      <Box
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        flexWrap="wrap"
        height="calc(100% - 90px)"
        px={5}
        style={{ overflowY: "scroll", overflowX: "hidden" }}
      >
        {billing?.map((invoice) => {
          return (
            <InvoiceItem key={invoice?.id?.toString()} invoice={invoice} />
          );
        })}
      </Box>
    </>
  );
}
