import { Box, Button, Card, Text } from "@/ui";
import { useTheme } from "@mui/material";
import { useAppContext } from "@/provider/common/AppProvider";
import { useSystemContext } from "@/provider/common/SystemProvider";
import DownloadIcon from "@mui/icons-material/Download";
import { CofidocHistory } from "@/api/billing/fetchCofidocHistory";
import { CofidocBilling } from "@/api/billing/fetchCofidocBill";
import { format } from "date-fns";
import { ReactComponent as SVGArrow } from "../../../../images/arrow.svg";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import Contracts from "./Contracts";

export default function Home() {
  const { getUser } = useSystemContext();
  const { relatedLastBill, lastHistory } = useAppContext();

  const theme = useTheme();

  return (
    <>
      <Box width="100%" flexDirection="column" justifyContent="center" height="90px" borderBottom={`solid 1px ${theme.palette.primary.light}`}>
        <Text fontSize={28} fontWeight="800" paddingLeft="70px">
          <span style={{ color: theme.palette.primary.main }}>B</span>onjour{" "}
          <span style={{ color: theme.palette.primary.main }}>{getUser()?.prenom}</span>
        </Text>
        <Text fontSize={16} fontWeight="300" paddingLeft="70px">
          Bienvenue dans votre espace client
        </Text>
      </Box>
      <Box
        width="100%"
        flexDirection="column"
        height="calc(100% - 90px)"
        px={3}
        style={{ overflowY: "scroll", overflowX: "hidden" }}
        className="hiddenScrollBarAndChildren"
        pb={5}
      >
        <Box width="100%" justifyContent="center" mt={4} flexDirection="column">
          <Contracts />
        </Box>
        <Box width="100%" justifyContent="center" alignItems="center" mb={4}>
          <RenderLastActivity lastHistory={lastHistory} relatedLastBill={relatedLastBill} />
        </Box>
        <Box width="100%" justifyContent="center" alignItems="center">
          <RenderLastBill relatedLastBill={relatedLastBill} />
        </Box>
      </Box>
    </>
  );
}

const RenderLastActivity = ({ relatedLastBill, lastHistory }: { lastHistory: CofidocHistory; relatedLastBill: CofidocBilling }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigationGestion = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = qs.stringify({ period: period.getTime(), tab: 1 }, { arrayFormat: "index" });
    navigate(`/account?${to}`);
  };

  return (
    <Card bgcolor="#FFE6EA" display="flex" flexDirection="column" maxHeight="350px" width="100%" alignItems="center">
      <Box width="80%" pt={3} flexDirection="column">
        <Text fontSize={25} fontWeight="700">
          Mon activité
        </Text>
        <Text textTransform="capitalize" fontWeight="700">
          {lastHistory?.date && format(new Date(lastHistory?.date), "MMM yyyy")}
        </Text>
      </Box>
      <Box justifyContent="center" alignItems="center" height="150px">
        <Card padding="20px">
          <Text textTransform="capitalize" fontWeight="700" fontSize={35} color={theme.palette.primary.main}>
            {lastHistory?.turnover} <span style={{ fontSize: "30px" }}>€ TTC</span>
          </Text>
          <Text fontWeight="700" fontSize={16} textAlign="center">
            Chiffre d'affaires
          </Text>
        </Card>
      </Box>
      <Box flexDirection="column">
        <Box
          width="100%"
          borderRadius={50}
          bgcolor={theme.colors.white}
          flexDirection="row"
          justifyContent="flex-end"
          position="relative"
          overflow="hidden"
        >
          <Text color={theme.palette.grey[500]} fontWeight="900" fontSize={12} p={1}>
            {lastHistory?.turnover}€
          </Text>
          <Box bgcolor={theme.palette.primary.main} borderRadius={50} position="absolute" left={0}>
            <Text fontWeight="900" color={theme.colors.white} fontSize={12} p={1}>
              {relatedLastBill?.amount}€
            </Text>
          </Box>
        </Box>
        <Box position="relative" justifyContent="space-between" pb={4}>
          <Box width="250px" height="80px" justifyContent="flex-start" alignItems="center">
            <SVGArrow
              style={{
                width: "35px",
                height: "35px",
                animation: "colorChange",
                position: "absolute",
                top: 15,
                left: 15,
              }}
            />
            <Text fontFamily="Caveat" fontSize={25} fontWeight="900" style={{ transform: "rotate(-7deg)" }} paddingLeft="45px">
              Ma facture Cofidoc
            </Text>
          </Box>
          <Box height="80px" justifyContent="flex-end" alignItems="flex-end">
            <Button
              onClick={navigationGestion}
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.colors.white,
                fontWeight: "700",
              }}
            >
              VOIR TOUT
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const RenderLastBill = ({ relatedLastBill }: { relatedLastBill: CofidocBilling }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const navigationGestion = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = qs.stringify({ period: period.getTime(), tab: 2 }, { arrayFormat: "index" });
    navigate(`/account?${to}`);
  };

  return (
    <Card display="flex" flexDirection="column" maxHeight="350px" width="100%" alignItems="center">
      <Box width="80%" py={3} flexDirection="column">
        <Text fontSize={25} fontWeight="700" mr={2}>
          Ma dernière facture
        </Text>
      </Box>
      <Box width="80%" flexDirection="row" justifyContent="space-between" py={4}>
        <Box flexDirection="column">
          <Text fontSize={13} fontWeight="700" color={theme.palette.grey[500]} pb={1}>
            Facture du {relatedLastBill?.date && format(new Date(relatedLastBill?.date), "MM/yyyy")}
          </Text>
          <Text fontSize={18} fontWeight="800" color={theme.palette.primary.main}>
            {relatedLastBill?.amount} <span style={{ fontSize: "13px" }}>€ TTC</span>
          </Text>
        </Box>
        <Box flexDirection="column">
          <Button
            onClick={navigationGestion}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.colors.white,
              fontWeight: "700",
            }}
          >
            VOIR TOUT
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
