import { Box, Button, Card, Text } from "@/ui";
import { useTheme } from "@mui/material";
import { useAppContext } from "@/provider/common/AppProvider";
import { useSystemContext } from "@/provider/common/SystemProvider";
import Contracts from "./Contracts";
import { CofidocHistory } from "@/api/billing/fetchCofidocHistory";
import { CofidocBilling } from "@/api/billing/fetchCofidocBill";
import { format } from "date-fns";
import { ReactComponent as SVGArrow } from "../../../images/arrow.svg";
import { useNavigate } from "react-router-dom";
import qs from "query-string";

export default function Home() {
  const { getUser } = useSystemContext();
  const { relatedLastBill, lastHistory } = useAppContext();

  return (
    <Box flexDirection="column" width="100%" height="100%" className="overBg" style={{ overflowY: "scroll" }} p={4} py={5} pl={0}>
      <Box
        width="100%"
        height="100%"
        flexDirection="column"
        bgcolor="#fafafaec"
        boxShadow="7px 7px 12px 0px #c3c3c3"
        borderRadius={5}
        alignItems="center"
      >
        <Box width="100%" flexDirection="column" p={5} height="20%">
          <Text fontSize={35} fontWeight="800">
            Bonjour {getUser()?.prenom},
          </Text>
          <Text fontSize={18} fontWeight="300">
            Bienvenue dans votre espace client
          </Text>
        </Box>
        <Box justifyContent="center" alignItems="center" flexDirection="row" width="100%" height="20%">
          <Contracts />
        </Box>
        <Box height="4%"/>
        <Box justifyContent="center" alignItems="center" flexDirection="row" width="100%" height="50%">
          <Box flex={0.35} height="100%" flexDirection="column" justifyContent="space-between">
            <RenderLastActivity lastHistory={lastHistory} relatedLastBill={relatedLastBill} />
          </Box>
          <Box flex={0.02} />
          <Box flex={0.35} height="100%" flexDirection="column" justifyContent="space-between">
            <Box height="40%" width="100%">
              <RenderLastBill relatedLastBill={relatedLastBill} />
            </Box>
            <Box height="50%" width="100%" justifyContent="center" alignItems="center">
              <div style={{ width: "100%", height: "100%" }} className="image-cofidoc-philippe-soual" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const RenderLastActivity = ({ relatedLastBill, lastHistory }: { lastHistory: CofidocHistory; relatedLastBill: CofidocBilling }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigationGestion = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = qs.stringify({ period: period.getTime(), tab: 1 }, { arrayFormat: "index" });
    navigate(`/account?${to}`);
  };

  return (
    <Card bgcolor="#FFE6EA" display="flex" flexDirection="column" maxHeight="350px" width="100%" alignItems="center">
      <Box width="80%" pt={3} flexDirection="column">
        <Text fontSize={25} fontWeight="700">
          Mon activité
        </Text>
        <Text textTransform="capitalize" fontWeight="700">
          {lastHistory?.date && format(new Date(lastHistory?.date), "MMM yyyy")}
        </Text>
      </Box>
      <Box justifyContent="center" alignItems="center" height="150px">
        <Card padding="20px">
          <Text textTransform="capitalize" fontWeight="700" fontSize={35} color={theme.palette.primary.main}>
            {lastHistory?.turnover} <span style={{ fontSize: "30px" }}>€</span>
          </Text>
          <Text fontWeight="700" fontSize={16} textAlign="center">
            Chiffre d'affaires
          </Text>
        </Card>
      </Box>
      <Box flexDirection="column" width="80%" pb={4}>
        <Box
          width="100%"
          borderRadius={50}
          bgcolor={theme.colors.white}
          flexDirection="row"
          justifyContent="flex-end"
          position="relative"
          overflow="hidden"
        >
          <Text color={theme.palette.grey[500]} fontWeight="900" fontSize={12} p={1}>
            {lastHistory?.turnover}€
          </Text>
          <Box bgcolor={theme.palette.primary.main} borderRadius={50} position="absolute" left={0}>
            <Text fontWeight="900" color={theme.colors.white} fontSize={12} p={1}>
              {relatedLastBill?.amount}€ TTC
            </Text>
          </Box>
        </Box>
        <Box position="relative" justifyContent="space-between">
          <Box width="250px" height="80px" justifyContent="flex-start" alignItems="center">
            <SVGArrow
              style={{
                width: "35px",
                height: "35px",
                animation: "colorChange",
                position: "absolute",
                top: 15,
                left: 15,
              }}
            />
            <Text fontFamily="Caveat" fontSize={25} fontWeight="900" style={{ transform: "rotate(-7deg)" }} paddingLeft="45px">
              Ma facture Cofidoc
            </Text>
          </Box>
          <Box height="80px" justifyContent="flex-end" alignItems="flex-end">
            <Button
              onClick={navigationGestion}
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.colors.white,
                fontWeight: "700",
              }}
            >
              VOIR TOUT
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const RenderLastBill = ({ relatedLastBill }: { relatedLastBill: CofidocBilling }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const navigationGestion = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = qs.stringify({ period: period.getTime(), tab: 2 }, { arrayFormat: "index" });
    navigate(`/account?${to}`);
  };

  return (
    <Card display="flex" flexDirection="column" maxHeight="350px" width="100%" alignItems="center">
      <Box width="80%" py={3} flexDirection="column">
        <Text fontSize={25} fontWeight="700">
          Ma dernière facture
        </Text>
      </Box>
      <Box width="80%" flexDirection="row" justifyContent="space-between" pt={4}>
        <Box flexDirection="column">
          <Text fontSize={13} fontWeight="700" color={theme.palette.grey[500]} pb={1}>
            Facture du {relatedLastBill?.date && format(new Date(relatedLastBill?.date), "MM/yyyy")}
          </Text>
          <Text fontSize={18} fontWeight="800" color={theme.palette.primary.main}>
            {relatedLastBill?.amount} <span style={{ fontSize: "13px" }}>€ TTC</span>
          </Text>
        </Box>
        <Box flexDirection="column">
          <Button
            onClick={navigationGestion}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.colors.white,
              fontWeight: "700",
            }}
          >
            VOIR TOUT
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
