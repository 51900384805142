import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from "@mui/material";
import { Box, Text, Card, Accordion } from "@/ui";
import { groupBy } from "lodash";
import { FilterPeriodMobile } from "./DateFilter";

export function ReportViewMobile({ reports, formatDate }: any) {
  const theme = useTheme();

  return (
    <>
      <Box
        width="100%"
        flexDirection="column"
        justifyContent="center"
        height="90px"
        borderBottom={`solid 1px ${theme.palette.primary.light}`}
      >
        <Text fontSize={28} fontWeight="800" paddingLeft="70px">
          <span style={{ color: theme.palette.primary.main }}>G</span>estion{" "}
          <span style={{ color: theme.palette.primary.main }}>C</span>ofidoc,
        </Text>
        <Text fontSize={16} fontWeight="300" paddingLeft="70px">
          Mes comptes rendus de facturation
        </Text>
      </Box>
      <Box
        width="100%"
        flexDirection="column"
        justifyContent="center"
        height="60px"
      >
        <FilterPeriodMobile />
      </Box>
      <Box
        width="100%"
        flexDirection="column"
        height="calc(100% - 150px)"
        px={3}
        style={{ overflowY: "scroll", overflowX: "hidden" }}
        className="hiddenScrollBarAndChildren"
      >
        {reports.length > 0 ? (
          <>
            {/* Accordéon pour les détails des remboursements */}
            {reports.map((report, index) => {
              const mapped = report.factures?.map((f) => {
                return { ...f, title: f?.libelleCaisse || f?.libelleMutuelle };
              });
              const grouped = groupBy(mapped, "title");
              return (
                <Box
                  key={report.date?.getTime()}
                  width="100%"
                  py={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Accordion
                    width="100%"
                    key={report.date.getTime() || index}
                    title={`Ma facturation du ${formatDate(report.date)}`}
                  >
                    {!!report.commentaire && (
                      <Box flexDirection="column" width="90%" pb={3}>
                        <Box height="1px" width={"100%"} bgcolor="#d5d5d5" />
                        <Box flexDirection="column" py={2} width="100%">
                          <Text fontSize={13}>Commentaire gestionnaire:</Text>
                          <Text fontSize={17}>{report.commentaire}</Text>
                        </Box>
                        <Box height="1px" width={"100%"} bgcolor="#d5d5d5" />
                      </Box>
                    )}
                    {Object?.keys(grouped).map((key) => {
                      return (
                        <Box
                          width="100%"
                          flexDirection="column"
                          alignItems="center"
                          mb={2}
                          mt={2}
                        >
                          <Accordion
                            width="90%"
                            key={report.date.getTime() || index}
                            title={`${key}`}
                            boxShadow="rgb(170, 170, 170) 0px 0px 10px -2px"
                          >
                            <Table
                              size="small"
                              style={{
                                border: "1px solid #e0e0e0",
                                width: "100%",
                              }}
                            >
                              <TableHead style={{ width: "100%" }}>
                                <TableRow style={{ width: "100%" }}>
                                  <TableCell
                                    style={{
                                      padding: 4,
                                      maxWidth: "25%",
                                      width: "25%",
                                    }}
                                  >
                                    Données
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: 4,
                                      maxWidth: "25%",
                                      width: "25%",
                                    }}
                                  >
                                    Patient
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: 4,
                                      maxWidth: "25%",
                                      width: "25%",
                                    }}
                                  >
                                    Date
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: 4,
                                      maxWidth: "25%",
                                      width: "25%",
                                    }}
                                  >
                                    Payé
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {grouped[key].map((facture, detailIndex) => (
                                  <>
                                    <TableRow
                                      key={`${facture?.numero}`}
                                      style={{
                                        width: "100%",
                                        backgroundColor:
                                          detailIndex % 2 === 0
                                            ? "#fff"
                                            : "#f3e7e765",
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          padding: 4,
                                          maxWidth: "25%",
                                          width: "25%",
                                        }}
                                      >
                                        <Text fontSize={9} fontWeight="600">
                                          n°{facture.numero}
                                        </Text>
                                        <Text fontSize={11}>
                                          {facture?.libelle}
                                        </Text>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: 4,
                                          maxWidth: "25%",
                                          width: "25%",
                                        }}
                                      >
                                        <Text fontSize={11}>
                                          {facture.patient}
                                        </Text>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: 4,
                                          maxWidth: "25%",
                                          width: "25%",
                                        }}
                                      >
                                        <Text fontSize={11}>
                                          {formatDate(new Date(facture.debut))}{" "}
                                          - {formatDate(new Date(facture.fin))}
                                        </Text>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: 4,
                                          maxWidth: "25%",
                                          width: "25%",
                                        }}
                                      >
                                        <Text fontSize={11}>
                                          {parseFloat(facture.montant).toFixed(
                                            2
                                          )}
                                          €
                                        </Text>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </Accordion>
                        </Box>
                      );
                    })}
                  </Accordion>
                </Box>
              );
            })}
          </>
        ) : (
          <Card flexDirection="column" display="flex">
            <Box
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              p={2}
              m={2}
            >
              <Text fontSize={18} fontWeight={500}>
                Aucun compte rendu pour cette période
              </Text>
            </Box>
          </Card>
        )}
      </Box>
    </>
  );
}
