import { fetchCofidocRefunds } from "@/api/billing/fetchCofidocRefunds";
import { useMongoContext } from "@/provider/common/MongoProvider";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { Refund } from "@/types/nurse";
import { useQueryParams } from "@/utils/useQueryParams";
import { parseISO, isValid, format } from "date-fns";
import React, { useState, useEffect } from "react";

export function RefundViewWrapper({ children }) {
  const { setLoading } = useSystemContext();
  const { client, user } = useMongoContext();
  const [refunds, setRefunds] = useState<Refund[]>([]);
  const { application } = useSystemContext();
  const params = useQueryParams();

  // Get refunds function
  async function getFilteredRefunds() {
    try {
      const refunds = await fetchCofidocRefunds({
        application,
      });
      setRefunds(refunds);
      return refunds?.sort((a, b) => a.date.getTime() - b.date.getTime());
    } catch (error) {
      console.log("error :", error);
    } finally {
    }
  }

  // Get data function
  async function getData() {
    try {
      console.log("--- getData ---");
      const dateFilter = new Date(Number(params.period));
      setLoading(true);

      const refunds = await getFilteredRefunds();
      // Filter refunds for the selected month and for the selected year
      const filteredAndSortedRefunds = refunds?.filter(
        (refund) =>
          refund.date.getMonth() === dateFilter.getMonth() &&
          refund.date.getFullYear() === dateFilter.getFullYear()
      );
      setRefunds(filteredAndSortedRefunds);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.period]);

  // Navigation Bar Component

  // Format date function
  const formatDate = (dateStr: number | Date): string => {
    let date = dateStr;
    if (typeof dateStr === "string") {
      date = parseISO(dateStr);
    }
    return isValid(date) ? format(date, "dd/MM/yyyy") : "Date invalide";
  };

  // Pluralize function
  const pluralize = (count: number, singular: string, plural: string) => {
    return count === 1 ? singular : plural;
  };

  return (
    <>
      {React.cloneElement(children, {
        formatDate,
        pluralize,
        refunds,
      })}
    </>
  );
}
