import { fetchAPI } from "@/utils/fetchAPI";

export type ProductName =
  | "Forfait avec Engagement"
  | "Gestion+ avec Engagement"
  | "Saisie PREMIER"
  | "Gestion+"
  | "eCofiCoffre";

export async function fetchCofidocRefunds({ application }) {
  try {
    const res = await fetchAPI({
      url: `/account/refunds`,
      options: {
        method: "POST",
      },
      application,
    });

    return res.map((c) => ({ ...c, date: new Date(c.date) }));
  } catch (error) {
    throw new Error();
  }
}
