import { useQueryParams } from "@/utils/useQueryParams";
import { format } from "date-fns";
import { useState } from "react";
import Calendar from "react-calendar";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Text, Button } from "@/ui";
import qs from "query-string";

export const FilterPeriodMobile = () => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [periodVisible, setPeriodVisible] = useState(false);
  //@ts-ignore
  const period = params.period
    ? new Date(Number(params.period))
    : new Date(1725141600000);

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      width="100%"
      position="relative"
    >
      <Box
        style={{ position: "relative" }}
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize={16} mr={2}>
          Période du :
        </Text>
        <Box
          p="2px"
          pl={1}
          pr={1}
          style={{
            borderRadius: 5,
            backgroundColor: "#fff",
            border: "solid 1px",
            borderColor: "#c7c7c7",
            cursor: "pointer",
            fontSize: 13,
            fontWeight: 600,
            maxHeight: "28px",
            minHeight: "28px",
            color: "#454545",
            width: 80,
          }}
          justifyContent="center"
          alignItems="center"
          onClick={() => setPeriodVisible(!periodVisible)}
        >
          {format(period, "MM/yyyy")}
        </Box>

        {periodVisible && (
          <Box
            style={{
              position: "absolute",
              top: "100%",
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#fff",
              boxShadow:
                "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
              borderRadius: "5px",
              overflow: "hidden",
              zIndex: 99999,
            }}
          >
            <Calendar
              maxDetail="year"
              onChange={(c) => {
                const p = new Date(c as any as string);
                let to = qs.stringify(
                  { ...params, period: p.getTime() },
                  { arrayFormat: "index" }
                );
                navigate(`${location.pathname}?${to}`, {
                  replace: true,
                });
                setPeriodVisible(false);
              }}
              maxDate={new Date()}
              value={period}
            />
            <Box p={1}>
              <Button onClick={() => setPeriodVisible(false)}>Fermer</Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
