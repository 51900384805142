import DownloadRefundPDF from "@/components/common/RefundPDF";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Box, Text, Card, Accordion } from "@/ui";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { FilterPeriodMobile } from "./DateFilter";
import { useTheme } from "@mui/material";
import { useQueryParams } from "@/utils/useQueryParams";

export function RefundViewMobileCpn({ refunds, formatDate, pluralize }: any) {
  const { getUser } = useSystemContext();
  const theme = useTheme();
  const params = useQueryParams();

  return (
    <>
      <Box width="100%" flexDirection="column" justifyContent="center" height="90px" borderBottom={`solid 1px ${theme.palette.primary.light}`}>
        <Text fontSize={28} fontWeight="800" paddingLeft="70px">
          <span style={{ color: theme.palette.primary.main }}>G</span>
          estion <span style={{ color: theme.palette.primary.main }}>C</span>
          ofidoc,
        </Text>
        <Text fontSize={16} fontWeight="300" paddingLeft="70px">
          Mes remboursements
        </Text>
      </Box>
      <Box width="100%" flexDirection="column" justifyContent="center" height="60px">
        <FilterPeriodMobile />
      </Box>

      <Box
        width="100%"
        flexDirection="column"
        height="calc(100% - 150px)"
        px={3}
        style={{ overflowY: "scroll", overflowX: "hidden" }}
        className="hiddenScrollBarAndChildren"
      >
        {refunds?.length > 0 ? (
          <>
            <Card flexDirection="column" display="flex" style={{ marginBottom: "16px" }}>
              <Box flexDirection="column" justifyContent="space-evenly" alignItems="center" p={3}>
                <Box justifyContent="center" alignItems="center" display="flex" flexDirection="column" flexShrink={0} mb={2}>
                  <Text fontSize={26} fontWeight={800}>
                    {refunds.reduce((acc, refund) => {
                      const total = refund?.total ? parseFloat(refund.total) : 0;
                      let number = Number(acc + total).toFixed(2);
                      return Number(number);
                    }, 0)}{" "}
                    €
                  </Text>
                  <Text fontSize={16}>
                    {refunds.length} {pluralize(refunds.length, "virement", "virements")} -{" "}
                    {refunds.filter((refund, index, self) => self.findIndex((r) => r.nomOrganisme === refund.nomOrganisme) === index).length}{" "}
                    {pluralize(
                      refunds.filter((refund, index, self) => self.findIndex((r) => r.nomOrganisme === refund.nomOrganisme) === index).length,
                      "organisme",
                      "organismes"
                    )}
                  </Text>
                </Box>

                {/* Bouton pour télécharger */}
                <DownloadRefundPDF data={refunds} period={params.period} currentUser={getUser()} />
              </Box>
            </Card>
            {refunds.map((refund, index) => (
              <Box width="100%" py={2} justifyContent="center" alignItems="center">
                <Accordion
                  width="100%"
                  key={refund._id || index}
                  title={`${formatDate(refund.date)} - ${refund.nomOrganisme} - ${parseFloat(refund.total).toFixed(2)} €`}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ padding: 4 }}>Facture</TableCell>
                        <TableCell style={{ padding: 4 }}>Patient</TableCell>
                        <TableCell style={{ padding: 4 }}>Date</TableCell>
                        <TableCell style={{ padding: 4 }}>Payé</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {refund.details.map((detail, detailIndex) => (
                        <TableRow
                          key={detailIndex}
                          style={{
                            backgroundColor: detailIndex % 2 === 0 ? "#fff" : "#f3e7e765",
                          }}
                        >
                          <TableCell style={{ padding: 4 }}>
                            <Text fontSize={12}>{detail.numFacture}</Text>
                          </TableCell>
                          <TableCell style={{ padding: 4 }}>
                            <Text fontSize={12}>{detail.nomPatient}</Text>
                          </TableCell>
                          <TableCell style={{ padding: 4 }}>
                            <Text fontSize={12}>
                              {formatDate(detail.debut)} - {formatDate(detail.fin)}
                            </Text>
                          </TableCell>
                          <TableCell style={{ padding: 4 }}>
                            <Text fontSize={12}>{parseFloat(detail.paye).toFixed(2)}€</Text>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Accordion>
              </Box>
            ))}
          </>
        ) : (
          <Card flexDirection="column" display="flex">
            <Box justifyContent="center" alignItems="center" width="100%" height="100%" p={2} m={2}>
              <Text fontSize={18} fontWeight={500}>
                Aucun remboursement pour cette période
              </Text>
            </Box>
          </Card>
        )}
      </Box>
    </>
  );
}
