import { ApplicationType } from "@/types/common";
import { fetchAPI } from "@/utils/fetchAPI";

type FetchRefreshAccessToken = {
  refreshToken: string;
};

export async function fetchRefreshAccessToken({
  refreshToken,
}: FetchRefreshAccessToken) {
  try {
    return fetchAPI({
      url: `/account/refreshToken`,
      options: {
        method: "POST",
        body: JSON.stringify({ refreshToken }),
      },
    });
  } catch (error) {
    throw new Error();
  }
}
